@import "https://fonts.googleapis.com/css?family=PT+Sans";
@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

:root {
  --custom-cursor: pointer;
}

/* Eric Meyer's CSS Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  background-color: #344050;
  line-height: 1;
  overflow-x: hidden;
  /* overflow: hidden; */
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* End of Eric Meyer's CSS Reset */

html {
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

body {
  font: 12px/18px "PT Sans", sans-serif;
  height: 100%;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-button,
*::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  border: 4px solid #1a1e25;
  background: #434b56;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover {
  background: #070808;
}

::-webkit-scrollbar-thumb:active,
*::-webkit-scrollbar-thumb:active {
  background: #4e5865;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background: #1a1e25;
  border: 0px solid #ffffff;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover,
*::-webkit-scrollbar-track:hover {
  background: #14181f;
}

::-webkit-scrollbar-track:active,
*::-webkit-scrollbar-track:active {
  background: #111419;
}

::-webkit-scrollbar-corner,
*::-webkit-scrollbar-corner {
  background: transparent;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: none;
  background-color: #fbf5ef;
  padding: 0.5em 0.7em;
  border-radius: 0.3em;
  font-size: 1em;
  vertical-align: middle;
  margin: 0;
  color: #000000;
  font-family: "PT Sans", sans-serif;
  transition: 0.3s;
  margin-left: 10px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  outline: none;
  background-color: #fbf5ef;
  border-color: #faac58;
  color: #000;
}

a {
  text-decoration: none;
  color: #006dff;
  cursor: pointer;
}

a:hover {
  color: rgb(5, 119, 241);
  transition: 0.3s;
}

.form-item {
  margin: 1.2em 0;
  color: #000000;
}

.startMenuItem .icon,
.quick-icon .icon {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: auto;
  height: auto;
}

.icon {
  display: inline-block !important;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -0.14em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  transform: rotate(0.0001deg);
}

a.btn,
.btn {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1.6em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 0.3em;
  text-align: center;
  font-size: 1em;
  border: none;
  cursor: pointer;
  margin: 0;
  transition: 0.3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon-pizap {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/pizap_photo_editor_logo_white.png);
}

.icon-gplus-light {
  background-image: url(./images/editor/login-icon-google-transparent.png);
}

.icon-twitter-light {
  background-image: url(./images/editor/login-icon-twitter-transparent.png);
}

.icon-facebook-light {
  background-image: url(./images/editor/login-icon-fb-transparent.png);
}

.icon-email {
  background-image: url(./images/editor/login-icon-email-transparent.png);
}

.Layout {
  width: 100%;
  min-width: 1000px;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.white {
  color: #fff;
}

.middle {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  /*    background-image: url(./images/editor/honeycomb_pattern.png); */
  background-image: url(https://cdn.pizap.com/pizapfiles/images/fabric-bg.jpg);
}

.middle:after {
  display: table;
  clear: both;
  content: "";
}

.container {
  width: 100%;
  float: left;
  overflow: auto;
  min-height: 100% !important;
  height: auto !important;
  height: 100%;
  background-size: 100% 100%;
}

.content {
  min-height: 100% !important;
  padding: 0 172px 0 374px;
}

.pro .content {
  padding: 0 0 0 374px;
}

.header {
  height: 80px;
  background-color: #344050;
}

.logo {
  padding-left: 10px;
}

.logo-text {
  height: 15px;
  max-width: 51px;
  margin-top: 14px;
  user-select: none;
}

.headerBtnBar {
  position: absolute;
  left: 360px;
  right: 0px;
  height: 20px;
  top: 2px;
  padding-left: 160px;
  text-align: center;
}

.save-share-group {
  position: absolute;
  right: 55px;
}

.headerBtnBar span {
  margin-left: 1px;
  vertical-align: top;
  color: #eaebf0;
  font-size: 14px;
  padding-left: 5px;
}

.username {
  font-size: 16px !important;
  margin-left: 0 !important;
  padding-right: 12px !important;
  padding-left: 0 !important;
  vertical-align: super;
}

.startPage .hamburger-icon {
  top: -3px;
}

.hamburger-icon {
  width: 15px;
  top: 5px;
  position: relative;
  margin-right: 5px;
}

.user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover;
  position: relative;
  top: -7px;
  vertical-align: middle;
  object-fit: cover;
}

.headerBtnBar div {
  display: inline-block;
  margin-left: 6px;
  vertical-align: -webkit-baseline-middle;
}

.btn {
  color: #eaebf0;
  border-radius: 5px;
  border: 0;
  padding: 6px;
  width: 100px;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
}

.saveBtn {
  padding-top: 13px;
}

.saveBtn:hover svg path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.saveBtn span {
  padding-left: 5px;
  color: #FFF;
  display: none;
}

.saveBtn:hover span {
  color: #1976d2;
  fill-opacity: 0.9922;
}

.shareBtn {
  padding-left: 30px;
  padding-top: 13px;
  padding-right: 10px;
}

.shareBtn:hover svg path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.shareBtn span {
  padding-left: 5px;
  color: #FFF;
  display: none;
}

.shareBtn:hover span {
  color: #1976d2;
  fill-opacity: 0.9922;
}

.a:hover {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.signinBtn {
  background-color: #1b1f25;
}

.footer {
  width: 100%;
  height: 40px;
  bottom: 0;
  position: absolute;
  color: #b8b9bb;
  font-size: 17px;
  background-color: #252a32;
}

.footer span {
  padding-right: 25px;
}

.footer table {
  width: 100%;
  margin: 10px;
}

.footer td {
  vertical-align: middle;
}

.footer tr td:first-child,
.footer tr td:last-child {
  width: 250px;
  color: #b8b9bb;
  text-align: right;
  padding-right: 30px;
}

.footer tr td:last-child img {
  padding-right: 5px;
}

.footer tr td:nth-child(2) {
  color: #474c52;
  text-align: center;
}

.left-sidebar {
  width: 74px;
  top: 0;
  position: absolute;
  background-color: #1b1f25;
  padding-bottom: 25px;
  overflow: hidden;
}

aside {
  text-align: center;
}

aside div svg {
  margin-top: 6px;
  max-height: 27px;
  max-width: 23px;
}

aside div:hover svg .svg-path {
  fill: #1b1f25 !important;
}

.a,
.c {
  fill: #fff;
}

.c {
  fill-opacity: 0.15;
}

.b {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 0.99;
}

.d {
  fill: none;
  stroke: #ffffff;
  stroke-width: 10.19;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 0.9765;
}

.a {
  fill-opacity: 0.99;
}

.r {
  fill: red;
}

.g {
  fill: lime;
}

.st0 {
  fill: #ffffff;
  fill-opacity: 0.9882;
}

.st1 {
  fill: #ffffff;
  fill-opacity: 0.9922;
}

.hamburger-icon:hover path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.menu-icon {
  fill: #ffffff;
  fill-opacity: 0.9922;
}

.menu-meme svg {
  margin-top: 15px;
  max-width: 30px;
}

#menu-stickers svg {
  margin-top: 12px;
  max-width: 27px !important;
}

#menu-text svg {
  margin-top: 7px;
  max-width: 21px !important;
  max-height: 24px;
}

#menu-border svg {
  margin-top: 9px;
  max-width: 23px !important;
}

#menu-addpic svg {
  max-width: 23px !important;
  max-height: 28px;
  margin-top: 8px;
}

#menu-touchup svg {
  margin-top: 12px;
  max-width: 35px !important;
  max-height: 21px;
}

.menu-text svg {
  max-height: 18px;
}

.left-sidebar span {
  top: 37px;
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
}

.logo-div {
  height: 28px !important;
  margin-top: 11px;
  margin-bottom: 8px !important;
}

.logo-div svg {
  margin-left: -10px;
}

.logo-div:hover svg path {
  fill: #006dff;
}

.logo-div div {
  display: inline-block;
  color: #ffffff;
  vertical-align: -webkit-baseline-middle;
  height: 18px !important;
  font-size: 12px;
}

.logo-div:hover div {
  color: #006dff;
}

.get-app {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: -1;
}

.get-app span {
  position: absolute;
  bottom: 54px;
  left: 0px;
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
}

.dropshadowOptionsContainer {
  background-color: #1a1f25;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;
}

.menu-container {
  width: 300px;
  left: 60px;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #252a32;
  overflow: auto;
  color: #b0b0b0;
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
}

.pro .menu-container {
  width: 300px;
  left: 74px;
  top: 0;
  bottom: 0px;
  position: absolute;
  background-color: #252a32;
  overflow-x: hidden;
  color: #b0b0b0;
  text-align: center;
}

.cutout-shapes-container {
  position: absolute;
  overflow-y: scroll;
  left: 0;
  bottom: 0;
  top: 46px;
}

#menu-container .photopicker-list-item {
  float: none;
  width: 80px;
  height: 65px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 0em;
  display: inline-table;
}

#menu-container.large .photopicker-list-item {
  width: 80px;
  position: relative;
}

.react-multi-carousel-list {
  padding-bottom: 10px !important;
}

#menu-container .photopicker-list-item .name {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  padding-top: 5px;
}

#menu-container .photopicker-list-item img {
  height: 46px;
  width: 70px;
  height: 70px;
  max-width: 80px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 8px;
}

#menu-container.large .photopicker-list-item img {
  height: 70px;
  width: 70px;
}

#menu-container .photopicker-photo-item {
  display: inline-table;
  width: auto;
  height: 80px;
  text-align: center;
  cursor: pointer;
  float: none;
  position: relative;
  width: 90px;
}

#menu-container .photopicker-photo-item img {
  max-height: 80px;
  max-width: 80px;
  width: auto;
  padding: 5px;
  vertical-align: middle;
  min-width: 20px;
}

#menu-container .photopicker-photo-item img:hover {
  scale: 1.1;
}

.menu-header {
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: #484d53 solid 1px;
  text-align: left;
  color: #fff;
  font-weight: 300;
}

.menu-header span {
  margin-left: 20px;
  line-height: 2.05;
}

.menu-header .clickable span {
  margin-left: 0px !important;
}

.custom-select select {
  background: #1b1f25;
  padding: 4px;
  color: #fff;
  border-radius: 5px;
}

.menu-sub-header {
  font-size: 14px;
  border-bottom: #484d53 solid 1px;
  text-align: center;
  color: #fff;
  font-weight: 300;
  padding-top: 6px;
  padding-bottom: 6px;
}

.menu-sub-header img {
  position: absolute;
  left: 10px;
}

canvas {
  margin: auto auto auto auto;
  display: block;
  position: relative;
  background-size: cover;
}

canvas.transparent {
  margin: auto auto auto auto;
  display: block;
  position: relative;
  background-size: cover;
  background-image: url("./images/editor/pizap-tranparent.png");
}

canvas.background {
  margin: auto auto auto auto;
  display: block;
  position: relative;
  background-size: cover;
  background-image: none;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.memePlusMinus {
  font-size: 28px;
  vertical-align: middle;
  padding-left: 10px;
}

#menu-meme-top {
  margin: 20px;
  text-align: left;
}

#menu-meme-bottom {
  margin: 20px;
  text-align: left;
}

.meme-font-size {
  padding-bottom: 20px;
  padding-top: 15px;
}

.meme-font-size span {
  padding-right: 10px;
}

.meme-font-size input {
  width: 150px;
}

.colorpicker {
  color: #ffffff;
  font-size: 12px;
  position: fixed;
  left: 51px;
  bottom: 0;
  z-index: 1000000;
}

#picker {
  cursor: crosshair;
  float: left;
  margin: 10px;
  border: 0;
}

.preview {
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  margin-left: 0;
  border-radius: 4px;
  cursor: pointer;
  height: 27px;
  width: 27px;
  vertical-align: middle;
  border-width: 1.5px;
  border-color: #1b1f25;
  border-style: solid;
}

.meme-text {
  width: 148px;
}

.memeHeader {
  display: inline-block;
  text-align: left;
  padding-bottom: 10px;
}

.upper {
  text-transform: uppercase;
}

.filepicker-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  font-family: "PT Sans", sans-serif;
  color: #424242;
  display: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.dialog-background {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: "PT Sans", sans-serif;
  color: #424242;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.filepicker-dialog.active {
  display: block;
}

.filepicker-dialog * {
  margin: 0;
  padding: 0;
}

.filepicker-dialog a {
  text-decoration: none;
  cursor: pointer;
}

.filepicker .icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
}

.filepicker .icon-computer {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/computer.png);
}

.filepicker .icon-dropbox {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/dropbox.png);
}

.filepicker .icon-google-drive {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/google_drive.png);
}

.filepicker .icon-one-drive {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/onedrive.png);
}

.filepicker-dialog-box {
  border: solid 1px #66686b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 288px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 2;
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

@keyframes modal-elastic-flip-animation {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

@keyframes modal-expand-horiz-animation-out {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(90deg);
  }
}

.cutout-dialog-box {
  border: solid 1px #66686b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 650px;
  height: 425px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 15px;
  text-align: center;
  z-index: 2;
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.cutout-dialog-box p {
  margin: 20px;
  margin-left: 46px;
  margin-right: 46px;
  text-align: left;
}

.feedback-dialog-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 950px;
  height: 450px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 15px;
  text-align: center;
  z-index: 2;
}

.feedback-dialog-box hr {
  margin-left: 40px;
  margin-right: 40px;
}

.feedback-dialog-box p {
  margin: 20px;
  margin-left: 46px;
  margin-right: 46px;
  text-align: left;
}

.cutout-traces-images img {
  height: 150px;
}

.cutout-refine img {
  height: 220px;
}

.cutout-traces-images img:nth-child(2) {
  margin-left: 15px;
  margin-right: 15px;
}

.cutout-dialog-box button {
  margin-top: 0;
}

.filepicker-navbar {
  position: relative;
  height: 45px;
  background-color: #131518;
  width: 100%;
  color: #fff;
}

.filepicker-navbar-right-btn {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 45px;
  padding: 0 15px;
  color: #fff;
}

.filepicker-navbar-right-btn:hover {
  color: #006dff;
}

.filepicker-navbar-left-btn {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 45px;
  padding: 0 15px;
  color: #fff;
}

.filepicker-title {
  text-align: left;
  line-height: 45px;
  font-size: 18px;
  padding-left: 12px;
  font-weight: lighter;
}

.cutout-title {
  text-align: center;
  line-height: 45px;
  font-size: 18px;
  font-weight: lighter;
}

.filepicker-side {
  position: relative;
  float: left;
  width: 200px;
  height: 555px;
  background-color: #eee;
  border-right: 1px solid #e6e6e6;
  margin-left: -1px;
}

.filepicker-main {
  position: relative;
  float: right;
  width: 600px;
  height: 555px;
  overflow: auto;
}

.filepicker-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  display: none;
}

.filepicker-loading:before {
  display: block;
  height: 50%;
  content: "";
}

.filepicker-loading.active {
  display: block;
}

.filepicker-list {
  background-color: #1c1f26;
  color: #fff;
  font-weight: 100;
  padding: 10px;
}

.filepicker-list-item {
  padding: 0.4em;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.filepicker-list-item.active {
  background-color: #333740;
  color: #fff;
}

#filepicker-sources-list .icon,
#filepicker-main-list .icon {
  /*margin-top: -0.3em;*/
  margin-right: 1em;
}

#filepicker-sources-list {
  height: 555px;
  overflow: auto;
}

.icon-facebook {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/facebook.png);
}

.icon-twitter {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/twitter-icon-20px.png);
}

.icon-tumblr {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/tumblr-icon-20px.png);
}

.icon-instagram {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/instagram.png);
}

.icon-flickr {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/flickr.png);
}

.icon-google {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/google.png);
}

.icon-backgrounds {
  background-image: url(./images/editor/pizap-stock-images-icon.png);
}

.icon-webcam {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/webcam.png);
}

.icon-picasa {
  background-image: url(https://cdn.pizap.com/pizapfiles/images/file_picker_icons/picasa.png);
}

.icon-google-photos {
  background-image: url(./images/editor/Google_Photos_icon.svg);
}

.photopicker {
  font-size: 16px;
}

.photopicker-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: "PT Sans", sans-serif;
  color: #424242;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.photopicker-dialog * {
  margin: 0;
  padding: 0;
}

.photopicker-dialog a {
  text-decoration: none;
  cursor: pointer;
}

.photopicker .icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.designpicker-dialog-box {
  border: solid 1px #66686b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  max-width: 975px;
  max-height: 600px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.photopicker-dialog-box {
  border: solid 1px #66686b;
  width: 98%;
  max-width: 1000px;
  max-height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.fontpicker-dialog-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 969px;
  height: 500px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.croppicker-dialog-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 970px;
  height: 500px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.photopicker-navbar {
  position: relative;
  height: 45px;
  background-color: #131518;
  width: 100%;
  color: #fff;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.spinner-navbar {
  position: relative;
  height: 45px;
  background-color: #1d1f25;
  width: 100%;
  color: #fff;
}

.photopicker-navbar-right-btn {
  position: absolute;
  top: 0px;
  right: 0;
  line-height: 45px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
}

.photopicker-navbar-left-btn {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 45px;
  padding: 0 0 0 15px;
  color: #fff;
}

.pw-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.photopicker-title {
  padding-left: 20px;
  line-height: 45px;
  font-size: 16px;
}

.photopicker-side {
  position: relative;
  float: left;
  width: 200px;
  height: 455px;
  background-color: #eee;
  border-right: 1px solid #e6e6e6;
  margin-left: -1px;
}

.photopicker-main {
  position: relative;
  float: right;
  width: 600px;
  height: 455px;
  overflow: auto;
}

.photopicker-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  display: none;
}

.photopicker-loading:before {
  display: block;
  height: 50%;
  content: "";
}

.photopicker-loading.active {
  display: block;
}

.photopicker-list-item {
  padding: 0.3em;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-weight: lighter;
  display: flex;
  align-items: center;
}

.photopicker-list-item img {
  padding-right: 10px;
  height: 30px;
  vertical-align: middle;
}

.photopicker-list-item span {
  vertical-align: middle;
}

.photopicker-list-item:link {
  color: #fff;
}

.photopicker-list-item:visited {
  color: #fff;
  font-weight: 100;
}

#photopicker-albums-list .icon,
#photopicker-main-list .icon {
  margin-right: 25px;
}

#photopicker-albums-list {
  height: 455px;
  overflow: auto;
  width: 264px;
  float: left;
  background-color: #1c1f26;
  overflow-x: hidden;
}

#photopicker-album-files {
  float: left;
  height: 81%;
  width: 100%;
  overflow: auto;
  background-color: #1c1f26;
}

#photopicker-album-files .react-loading-skeleton{
  width: 180px !important;
  height: 120px !important;
}
#collagepicker-album-files {
  padding: 10px;
  position: absolute;
  left: 160px;
  width: 82%;
  float: left;
  height: 89%;
  overflow: auto;
  background-color: #242730;
}

#designpicker-album-files {
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
  height: 433px;
  width: 100%;
  max-height: 91%;
  height: 91%;
  overflow: auto;
  background-color: #1c1f26;
}

.photopicker-photo-item {
  width: 112px;
  height: 112px;
  float: left;
  margin: 1px;
  position: relative;
}

#photopicker-album-files img,
#designpicker-album-files img {
  height: 120px;
  object-fit: contain;
  object-position: center;
}

#photopicker-album-files .grid>figure>img {
  width: 180px !important;
}

#collagepicker-album-files img {
  width: 112px;
  height: 112px;
  object-fit: contain;
  object-position: center;
}

.designpicker .photopicker-photo-item {
  height: 160px !important;
  width: 135px;
  text-align: center;
}

.designpicker .photopicker-photo-item span {
  display: block;
  font-size: 11px;
  color: #aaa;
  text-align: center;
}

@supports (-ms-ime-align: auto) {

  #photopicker-album-files img,
  #designpicker-album-files img,
  #collagepicker-album-files img {
    height: auto;
  }
}

.login-dialog {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 390px;
  height: 500px;
  border-radius: 8px;
  background-color: #141517;
  z-index: 1000;
  margin-top: -225px;
}

.login-container {
  margin: 8px;
  height: 459px;
}

.login-logo-container {
  height: 65px;
  background-color: #1b1f25;
}

.login-logo-container .icon-pizap {
  height: 30px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 17px;
}

.colorBar {
  margin-top: 11px;
  z-index: 10;
}

.login-logo-container .colorBar div {
  height: 6px;
  z-index: 10;
}

.login-main-btns {
  color: #fff;
  text-align: center;
}

.store-overview .login-main-btns {
  width: 389px;
  height: 381px;
}

#storeOptions {
  text-align: center;
  text-align: -webkit-center;
}

.app-logo {
  cursor: pointer;
}

.close-btn {
  color: #fff;
  cursor: pointer;
}

.mobile-popup {
  z-index: 5;
  position: absolute;
  padding: 20px;
  background: #16171a;
  border-radius: 12px;
  bottom: 45px;
  left: 45px;
  text-align: right;
}

.mobile-popup .app-store {
  padding: 10px 20px;
}

.mobile-popup .google-play {
  margin-top: 30px;
}

.login-main-email {
  color: #fff;
  text-align: center;
  height: 302px;
  margin-top: 30px;
  padding-top: 34px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  padding-bottom: 20px;
  width: 302px;
  margin-left: auto;
  margin-right: auto;
}

.store-overview .login-main-email {
  width: 389px;
  height: 381px;
}

.login-main-forgot {
  color: #fff;
  text-align: center;
  background-color: #0d0f12;
  height: 383px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  border-radius: 8px;
  border: solid 1px #66686b;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.login-main-forgotResponse {
  color: #fff;
  text-align: center;
  background-color: #0d0f12;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 428px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.login-main-forgotResponse .forgot-content {
  font-size: 18px;
  line-height: 18px;
  padding: 56px;
}

.pro-logo {
  float: right;
}

.forgotError {
  padding-top: 100px;
  font-size: 21px;
  display: block;
}

.pro-logo span {
  color: lawngreen;
}

.sourceRow img {
  height: 20px;
  display: block;
  padding-left: 22px;
}

.inline {
  display: inline-block;
}

.store-overview .login-main-forgot {
  width: 389px;
  height: 381px;
}

.login-main-register {
  color: #fff;
  text-align: center;
  height: 287px;
  margin-top: 30px;
  padding-top: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  padding-bottom: 20px;
  width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.store-overview .login-main-register {
  width: 389px;
  height: 381px;
}

.login-main-email input {
  width: 242px;
  margin-bottom: 10px;
  height: 33px;
  font-size: 15px;
  margin-left: 0;
}

.login-main-forgot input {
  width: 275px;
  height: 32px;
  font-size: 16px;
  margin-left: 0;
}

.login-main-register input {
  width: 275px;
  margin-bottom: 15px;
  height: 36px;
  font-size: 14px;
  margin-left: 0;
}

.login-main-email .btn-green {
  width: 283px;
  margin-bottom: 17px;
  height: 40px;
  line-height: 22px;
  font-size: 18px;
}

.login-main-forgot .btn-green {
  width: 124px;
  margin-bottom: 20px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 36px;
}

.login-main-forgot span {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.login-main-register .btn-green {
  width: 283px;
  height: 40px;
  line-height: 23px;
  font-size: 18px;
}

.create-account {
  font-size: 15px;
  margin-bottom: 12px;
  margin-top: 24px;
}

.create-account a {
  color: #5393da !important;
}

.link {
  color: #5393da !important;
}

.alreadySigned a {
  color: #5393da !important;
}

.forgot-password {
  font-size: 15px;
  padding-left: 26px;
}

.login-main-btns a.btn {
  display: block;
  width: 275px;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  position: relative;
}

.store-overview .login-main-btns a.btn {
  height: 52px;
}

.login-main-btns .icon {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  top: 13px;
}

.login-main-btns span {
  font-size: 14px;
  margin-top: 3px;
  display: inherit;
}

.store-overview .login-main-btns span {
  font-size: 20px;
  margin-top: 18px;
  display: inline-block;
}

.login-instruction {
  margin-top: 0px !important;
  padding-top: 20px;
  margin-bottom: 20px;
  display: block;
  font-size: 16px;
}

.login-main-btns .login-instruction {
  font-size: 18px;
}

.login-forgot {
  margin-top: 0px !important;
  padding-top: 25px;
  margin-bottom: 22px;
  display: block;
  font-size: 16px;
  width: 275px;
  margin-left: auto;
  margin-right: auto;
}

.register-instruction {
  margin-top: 12px !important;
  padding-top: 5px;
  margin-bottom: 24px;
  display: block;
  font-size: 18px;
}

.login-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  font-size: 35px;
}

.login-close:hover {
  color: #1467c2;
}

.btn.btn-green {
  background-color: #006dff;
  margin-left: 0px;
}

.btn.btn-green:hover {
  background-image: linear-gradient(315deg, #8996d8, #0349c8 74%);
}

.btn.btn-facebook {
  background-color: #3b5998;
}

.btn.btn-gplus {
  background-color: #ffffff;
  color: #494949;
}

.btn.btn-email {
  background-color: #344050;
}

.btn.btn-twitter {
  background-color: #55acee;
}

.webcam-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "PT Sans", sans-serif;
  color: #424242;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.webcam-dialog-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 640px;
  height: 590px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.webcam-navbar {
  position: relative;
  height: 45px;
  background-color: #343f4f;
  width: 100%;
  color: #fff;
}

.webcam-navbar-right-btn {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 45px;
  padding: 0 15px;
  color: #fff;
}

.webcam-title {
  text-align: left;
  line-height: 45px;
  font-size: 18px;
  padding-left: 12px;
  font-weight: lighter;
}

#camera {
  width: 640px;
  height: 480px;
}

.btn.btn-snap {
  background-color: #00897b;
  margin-left: 0px;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
  width: 175px;
}

.btn.btn-snap:hover {
  background-color: #19a798;
}

.editor-properties-holder {
  position: absolute;
  left: 250px;
  height: 160px;
  top: 100px;
  z-index: 1;
}

@supports (-ms-ime-align: auto) {
  .editor-properties-holder {
    height: 170px;
  }
}

.editor-properties-holder-pro {
  position: absolute;
  left: 250px;
  top: 100px;
  z-index: 3;
}

.color-window-holder {
  position: absolute;
  left: 335px;
  height: 370px;
  margin-top: auto;
  margin-bottom: auto;
  bottom: 0px;
  top: 0px;
  z-index: 10;
  border-radius: 7px;
  background: #252a32;
  border: solid 1px #66686b;
}

.editor-collage-holder {
  position: absolute;
  left: 250px;
  top: 100px;
  z-index: 3;
}

.hotspot-holder {
  height: 270px;
}

.editor-collage-holder input {
  width: 90%;
}

.editor-collage-holder-pro input {
  width: 90%;
}

@supports (-ms-ime-align: auto) {
  .editor-collage-holder {
    height: 135px;
  }
}

.editor-collage-holder-pro {
  position: absolute;
  left: 250px;
  height: 125px;
  top: 100px;
  z-index: 1;
}

.editor-properties-dialog {
  margin: 0 auto;
  height: 100%;
  color: #fff;
}

.color-window-dialog {
  width: 270px;
  margin: 0 auto;
  height: 100%;
  color: #fff;
}

.sketch-picker {
  background-color: #161b1e !important;
  width: 250px !important;
  border-radius: 0px 0px 4px 4px !important;
}

.right {
  float: right;
  margin-right: 10px;
}

.color-window-dialog .property-mover {
  height: 25px;
  padding-top: 4px;
}

.color-window-dialog .property-mover svg {
  margin-left: 10px;
}

.color-window-dialog .property-mover .active {
  background-color: #006dff;
  border-radius: 2px;
}

.eyedropper {
  cursor: url("./images/editor/color-dropper-icon.png") 1 23, auto !important;
}

.color-window-dialog .property-mover span {
  padding-left: 10px;
  font-size: 14px;
}

.color-window-dialog .flexbox-fix span {
  color: #fff !important;
}

.color-window-dialog .saturation-white>div:nth-child(2)>div {
  width: 8px !important;
  height: 8px !important;
  transform: translate(-4px, -4px) !important;
}

.sketch-picker>div:nth-child(4)>div {
  border-top: 0px solid #424a55 !important;
  padding: 0px 0px 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
  margin: 0px 3px 3px 0px !important;
}

.sketch-picker>div:nth-child(4)>div {}

.undo-holder {
  position: absolute;
  z-index: 4;
  padding-left: 10px;
}

.undo-dialog {
  width: 82px;
  margin: 0 auto;
  height: 100%;
}

.undoBtn {
  color: #fff;
  float: left;
  text-align: center;
}

.undoBtn:hover {
  color: #1965b1;
}

.undoBtn:hover .a {
  fill: #1965b1;
}

.undoBtn.disabled {
  color: #777777;
}

.undoBtn.disabled .a {
  fill: #777;
}

.undoBtn.disabled:hover .a {
  fill: #777;
}

.undoBtn span {
  vertical-align: super;
  padding-left: 10px;
  display: none;
}

.redoBtn {
  color: #fff;
  text-align: center;
}

.redoBtn:hover {
  color: #1965b1;
}

.redoBtn:hover .a {
  fill: #1965b1;
}

.redoBtn.disabled {
  color: #777777;
}

.redoBtn.disabled .a {
  fill: #777;
}

.redoBtn.disabled:hover .a {
  fill: #777;
}

.redoBtn span {
  vertical-align: super;
  padding-left: 10px;
  display: none;
}

.prop-group {
  padding: 15px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
}

.prop-group-template {
  width: 38px;
  height: 30px !important;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
}

.prop-group-wide {
  width: 88px;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 1px;
  position: relative;
  top: 5px;
}

.prop-labels {
  position: absolute;
  top: 0;
  left: 10px;
  color: #fff;
}

.collage-hover-holder .prop-group-template {
  text-align: center !important;
}

.collage-hover-holder .prop-group-template svg {
  padding-left: 0 !important;
  margin: 6px;
}

.prop-group-template:hover svg path {
  fill: #1965b1;
}

.prop-blend {
  margin-top: 6px;
  margin-left: 6px;
}

.prop-blend select {
  background-color: #ffffff;
}

.paint_brush {
  background-image: url(./images/editor/paint_brush.png);
  background-size: 56px 57px;
  width: 75px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 15px;
  background-color: #2d3644;
  border-radius: 15px;
}

.paint_brush.disabled {
  background-image: url(./images/editor/paint_brush_disabled.png);
  background-color: #252a32;
}

.eraser {
  background-image: url(./images/editor/eraser.png);
  background-size: 60px 52px;
  width: 75px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #2d3644;
  border-radius: 15px;
}

.eraser.disabled {
  background-image: url(./images/editor/eraser_disabled.png);
  background-color: #252a32;
}

.icon-row {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.col-2 {
  width: 135px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.col-3 {
  width: 60px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding-left: 0;
}

.font-size {
  font-size: 12px;
  color: #fff;
  display: block;
  text-align: left;
  margin-bottom: 6px;
}

.alignToolsHidden {
  display: inline-block;
  width: 148.72px;
}

.alignIcon {
  height: 16px;
  margin-right: 5px;
  padding: 8px;
  cursor: pointer;
}

.filepicker-list-item:hover .st0 {
  fill: #006dff;
}

.prop-group:hover .st0 {
  fill: #006dff;
}

.prop-group:hover span {
  color: #006dff;
}

.alignIcon-last {
  height: 16px;
  padding: 10px;
}

.alignIcon.active,
.input-number.active,
.alignIcon-last.active {
  border-radius: 5px;
  background-color: #1a1e25 !important;
}

.font {
  font-size: 12px;
  color: #fff;
  display: block;
  text-align: left;
}

.fontSelected {
  background-color: #1a1e25;
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
  color: #ffffff;
  background-image: url(./images/editor/bottom-arrow-simple.png);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  width: 100%;
  cursor: pointer;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.fontTabContainer {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 250px;
  z-index: 20;
}

.glow {
  background-image: url(./images/editor/text-glow.png);
  background-size: 36px 43px;
  background-position: left;
  background-repeat: no-repeat;
  padding: 10px;
  padding-left: 50px;
  margin-bottom: 15px;
  font-size: 17px;
  color: #fff;
  display: block;
}

.circle-active {
  background: #76e96e !important;
}

.circle_50 {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: #555a67;
  display: inline-block;
}

.circle_36 {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #555a67;
  display: inline-block;
  margin-right: 19px;
}

.circle_26 {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background: #555a67;
  display: inline-block;
  margin-right: 19px;
}

.circle_16 {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #555a67;
  display: inline-block;
  margin-right: 19px;
}

.circle_8 {
  width: 12px;
  height: 12px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #555a67;
  display: inline-block;
  margin-right: 19px;
}

.brush-color {
  background-image: url(./images/editor/paint-color.png);
  background-size: 35px 38px;
  background-position: left;
  background-repeat: no-repeat;
  padding: 10px;
  padding-left: 50px;
  font-size: 17px;
  color: #fff;
  display: block;
  margin-bottom: 15px;
}

.color_20 {
  width: 14px;
  height: 14px;
  background: #76e96e;
  display: inline-block;
  margin-right: 3.5px;
  margin-bottom: 1px;
}

.color1_1 {
  background-color: #330000;
}

.color1_2 {
  background-color: #331900;
}

.color1_3 {
  background-color: #333300;
}

.color1_4 {
  background-color: #193300;
}

.color1_5 {
  background-color: #003300;
}

.color1_6 {
  background-color: #003319;
}

.color1_7 {
  background-color: #003333;
}

.color1_8 {
  background-color: #001933;
}

.color1_9 {
  background-color: #000033;
}

.color1_10 {
  background-color: #190033;
}

.color1_11 {
  background-color: #330033;
}

.color1_12 {
  background-color: #330019;
}

.color1_13 {
  background-color: #000000;
}

.color2_1 {
  background-color: #660000;
}

.color2_2 {
  background-color: #663300;
}

.color2_3 {
  background-color: #666600;
}

.color2_4 {
  background-color: #336600;
}

.color2_5 {
  background-color: #006600;
}

.color2_6 {
  background-color: #006633;
}

.color2_7 {
  background-color: #006666;
}

.color2_8 {
  background-color: #003366;
}

.color2_9 {
  background-color: #000066;
}

.color2_10 {
  background-color: #330066;
}

.color2_11 {
  background-color: #660066;
}

.color2_12 {
  background-color: #660033;
}

.color2_13 {
  background-color: #202020;
}

.color3_1 {
  background-color: #990000;
}

.color3_2 {
  background-color: #994c00;
}

.color3_3 {
  background-color: #999900;
}

.color3_4 {
  background-color: #4c9900;
}

.color3_5 {
  background-color: #009900;
}

.color3_6 {
  background-color: #00994c;
}

.color3_7 {
  background-color: #009999;
}

.color3_8 {
  background-color: #004c99;
}

.color3_9 {
  background-color: #000099;
}

.color3_10 {
  background-color: #4c0099;
}

.color3_11 {
  background-color: #990099;
}

.color3_12 {
  background-color: #99004c;
}

.color3_13 {
  background-color: #404040;
}

.color4_1 {
  background-color: #cc0000;
}

.color4_2 {
  background-color: #cc6600;
}

.color4_3 {
  background-color: #cccc00;
}

.color4_4 {
  background-color: #66cc00;
}

.color4_5 {
  background-color: #00cc00;
}

.color4_6 {
  background-color: #00cc66;
}

.color4_7 {
  background-color: #00cccc;
}

.color4_8 {
  background-color: #0066cc;
}

.color4_9 {
  background-color: #0000cc;
}

.color4_10 {
  background-color: #6600cc;
}

.color4_11 {
  background-color: #cc00cc;
}

.color4_12 {
  background-color: #cc0066;
}

.color4_13 {
  background-color: #606060;
}

.color5_1 {
  background-color: #ff0000;
}

.color5_2 {
  background-color: #ff8000;
}

.color5_3 {
  background-color: #ffff00;
}

.color5_4 {
  background-color: #80ff00;
}

.color5_5 {
  background-color: #00ff00;
}

.color5_6 {
  background-color: #00ff80;
}

.color5_7 {
  background-color: #00ffff;
}

.color5_8 {
  background-color: #0080ff;
}

.color5_9 {
  background-color: #0000ff;
}

.color5_10 {
  background-color: #7f00ff;
}

.color5_11 {
  background-color: #ff00ff;
}

.color5_12 {
  background-color: #ff007f;
}

.color5_13 {
  background-color: #808080;
}

.color6_1 {
  background-color: #ff3333;
}

.color6_2 {
  background-color: #ff9933;
}

.color6_3 {
  background-color: #ffff33;
}

.color6_4 {
  background-color: #99ff33;
}

.color6_5 {
  background-color: #33ff33;
}

.color6_6 {
  background-color: #33ff99;
}

.color6_7 {
  background-color: #33ffff;
}

.color6_8 {
  background-color: #3399ff;
}

.color6_9 {
  background-color: #3333ff;
}

.color6_10 {
  background-color: #9933ff;
}

.color6_11 {
  background-color: #ff33ff;
}

.color6_12 {
  background-color: #ff3399;
}

.color6_13 {
  background-color: #a0a0a0;
}

.color7_1 {
  background-color: #ff6666;
}

.color7_2 {
  background-color: #ffb266;
}

.color7_3 {
  background-color: #ffff66;
}

.color7_4 {
  background-color: #b2ff66;
}

.color7_5 {
  background-color: #66ff66;
}

.color7_6 {
  background-color: #66ffb2;
}

.color7_7 {
  background-color: #66ffff;
}

.color7_8 {
  background-color: #66b2ff;
}

.color7_9 {
  background-color: #6666ff;
}

.color7_10 {
  background-color: #b266ff;
}

.color7_11 {
  background-color: #ff66ff;
}

.color7_12 {
  background-color: #ff66b2;
}

.color7_13 {
  background-color: #c0c0c0;
}

.color8_1 {
  background-color: #ff9999;
}

.color8_2 {
  background-color: #ffcc99;
}

.color8_3 {
  background-color: #ffff99;
}

.color8_4 {
  background-color: #ccff99;
}

.color8_5 {
  background-color: #99ff99;
}

.color8_6 {
  background-color: #99ffcc;
}

.color8_7 {
  background-color: #99ffff;
}

.color8_8 {
  background-color: #99ccff;
}

.color8_9 {
  background-color: #9999ff;
}

.color8_10 {
  background-color: #cc99ff;
}

.color8_11 {
  background-color: #ff99ff;
}

.color8_12 {
  background-color: #ff99cc;
}

.color8_13 {
  background-color: #e0e0e0;
}

.color9_1 {
  background-color: #ffcccc;
}

.color9_2 {
  background-color: #ffe5cc;
}

.color9_3 {
  background-color: #ffffcc;
}

.color9_4 {
  background-color: #e5ffcc;
}

.color9_5 {
  background-color: #ccffcc;
}

.color9_6 {
  background-color: #ccffe5;
}

.color9_7 {
  background-color: #ccffff;
}

.color9_8 {
  background-color: #cce5ff;
}

.color9_9 {
  background-color: #ccccff;
}

.color9_10 {
  background-color: #e5ccff;
}

.color9_11 {
  background-color: #ffccff;
}

.color9_12 {
  background-color: #ffcce5;
}

.color9_13 {
  background-color: #ffffff;
}

.main-page {
  top: 40px !important;
}

.d-flex {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.color-picker {
  display: flex;
  align-items: center;
  align-content: center;
}

.transparent {
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
}

#menu-meme-top .color-picker {
  width: auto;
  vertical-align: middle;
}

#menu-meme-bottom .color-picker {
  width: auto;
  vertical-align: middle;
}

.color-picker input {
  margin-left: 10px;
}

.color-picker-horizontal input {
  margin-right: 10px;
}

.memeClear {
  float: right;
  color: #fff;
  font-size: 16px;
}

#preview-paint {
  display: block;
  margin-left: 15px;
}

.color-picker-text {
  color: #fff;
  font-size: 14px;
}

.color-picker-horizontal #preview-paint {
  margin: 0;
  margin-left: 10px;
  display: inline-block;
}

.color-picker-horizontal input[type="range"] {
  margin: 0;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  top: 17px;
  left: 158px;
  width: 123px;
}

#fontRange {
  width: 135px;
}

#spacingRange {
  width: 135px;
}

#heightRange {
  width: 135px;
}

.font-select {
  width: 100%;
  margin-top: 15px;
}

.font-select {
  background: #252a32;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  color: #fff;
}

.text-bubble {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.text-area {
  resize: vertical;
  width: 100%;
  height: 75px;
  margin-left: 0;
  font-size: 16px;
  background-color: #1b1f25 !important;
  color: #fff !important;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  overflow: hidden;
}

.font-selection {
  height: 100%;
  overflow: scroll;
  font-size: 18px;
  background-color: #fff;
  color: #000;
  text-align: left;
  width: 232px;
  overflow-x: hidden;
}

.font-option {
  display: block;
  height: 48px;
  white-space: nowrap;
}

.tab {
  width: 116px;
  display: inline-block;
  text-align: center;
  background-color: #363e4b;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tab.selected {
  background-color: #1a1e25;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #999;
  float: right;
  display: inline-block;
  margin-left: 10px;
  margin-top: 12px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #999;
  float: right;
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

#cutoutCanvas {
  display: none;
  background-color: transparent;
}

.btnCutout {
  width: 71px;
  margin-bottom: 5px;
}

.cutouts {
  display: inline-block;
}

#menu-container .cutouts .photopicker-photo-item {
  float: left;
  width: 55px;
  height: 55px;
  text-align: center;
  cursor: pointer;
}

#menu-container .cutouts .photopicker-photo-item img {
  padding-right: 0;
  height: 45px;
  max-width: 55px;
  padding-top: 5px;
}

button:focus {
  outline: 0;
}

.zoomRange {
  width: 100%;
}

.status-bar {
  position: relative;
  height: 97px;
}

.status-circle:first-child {
  position: absolute;
  left: 8px;
  top: 5px;
}

.status-circle:nth-child(3) {
  position: absolute;
  left: 105px;
  top: 5px;
}

.status-circle:last-child {
  position: absolute;
  left: 200px;
  top: 5px;
}

.status-circle {
  background-color: #333942;
  color: #6e7176;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  display: table;
  text-align: center;
}

.connector:nth-child(2) {
  position: absolute;
  top: 26px;
  left: 55px;
}

.connector:nth-child(4) {
  position: absolute;
  top: 26px;
  left: 150px;
}

.connector {
  height: 6px;
  width: 50px;
  background-color: #333942;
}

.status-circle span {
  font-size: 24px;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
}

.status-blue {
  background-color: #1976d2;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: table;
  text-align: center;
  margin-left: 4px;
  margin-top: 4px;
  color: #fff;
}

.status-grey {
  background-color: #333942;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: table;
  text-align: center;
  margin-left: 4px;
  margin-top: 4px;
}

.status-text {
  position: absolute;
  top: 62px;
  font-family: "PT Sans", sans-serif;
  font-size: 11px;
  color: #6e7176;
}

.status-text:first-child {
  left: 7px;
}

.status-text:last-child {
  left: 195px;
}

.status-text:nth-child(2) {
  left: 95px;
}

.status-text.active {
  color: #fff;
}

.cutout-tabs {
  width: 100%;
  height: 50px;
}

.cutout-tab {
  width: 50%;
  display: inline-block;
  background-color: #1b1f25;
  height: 100%;
  font-family: "PT Sans", sans-serif;
  font-size: 22px;
  background-repeat: no-repeat;
}

.cutout-tab.active {
  width: 50%;
  display: inline-block;
  background-color: #252a32;
}

.cutout-tab>img {
  margin-left: 5px;
  margin-top: 12px;
  vertical-align: middle;
}

.cutout-tab>span {
  margin-left: 10px;
  vertical-align: bottom;
  color: #fff;
}

.cutout-step1 {
  display: block;
  margin: 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: justify;
}

.cutout-curve img {
  padding-right: 15px;
  vertical-align: text-top;
}

.cutout-curve input {
  margin-right: 15px;
  vertical-align: bottom;
}

.cutout-curve span {
  vertical-align: bottom;
}

.cutout-curve div {
  margin-bottom: 5px;
}

.cutoutFinishBtn {
  width: 110px;
  border-radius: 24px;
  background: #006dff;
}

.cutoutCancelBtn {
  background-color: #5a5a5a;
  border-radius: 24px;
  width: 110px;
}

.cutoutChangeBtn {
  background-color: #5a5a5a;
  border-radius: 24px;
  width: 125px;
}

.addText {
  background-color: #0d0d0d;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.addText.heading {
  font-size: 33px;
  font-weight: bold;
}

.addText.heading:hover {
  color: #006dff;
}

.addText.subheading {
  font-size: 22px;
}

.addText.subheading:hover {
  color: #006dff;
}

.addText.small {
  font-size: 15px;
}

.addText.small:hover {
  color: #006dff;
}

.image-text {
  background-color: #0d0d0d;
  border-radius: 8px;
  height: 115px;
  width: 115px;
}

.image-text:hover {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.addText img {
  height: 20px;
  vertical-align: text-bottom;
  padding-right: 8px;
  padding-left: 5px;
}

.collageApplyText {
  background-color: #1976d2;
  border-radius: 2px;
  width: 110px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.mainBtn {
  background-color: #333945;
  width: 242px;
  margin: 15px 10px 0;
  border-radius: 2px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 13px;
  font-size: 11px;
}

.mainAutofixBtn {
  width: 72px;
  height: 50px;
  margin: 10px 0px 0px 10px;
  padding-top: 5px;
  color: #fff;
  float: left;
}

.mainAutofixBtn span {
  display: block;
}

.mainCropBtn {
  width: 72px;
  height: 50px;
  margin: 10px 5px 0px 5px;
  padding-top: 5px;
  float: left;
  color: #fff;
}

.mainBtn:hover svg path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.mainBtn:hover {
  color: #1976d2;
}

.mainCropBtn span {
  display: block;
}

.mainRotateBtn {
  width: 72px;
  height: 50px;
  margin: 10px 0 0px 5px;
  padding-top: 5px;
  float: left;
  color: #fff;
}

.mainRotateBtn span {
  display: block;
}

.mainItemDiv input {
  display: block;
  width: 262px;
}

.mainItemDiv {
  text-align: left;
  margin-left: 15px;
  margin-top: 10px;
  color: #fff;
  font-size: 11px;
  padding-bottom: 7px;
}

.mainItemDiv span {
  text-align: left;
}

.menuad {
  display: none;
}

@media (min-height: 650px) {
  .menuad {
    width: 300px;
    height: 250px;
    position: absolute;
    bottom: 6px;
    left: 6px;
    display: block;
  }

  .menu-container {
    bottom: 300px;
  }
}

.leftmenu {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 188px;
  z-index: 3;
}

.leftmenu .items-container {
  height: calc(100vh - 200px);
  overflow-y: auto;
  width: 200px;
}

.menu-bar {
  background-color: #1b1f25;
}

.navbar {
  height: 46px;
  background-color: #1b1f25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  z-index: 3;
  user-select: none;
  box-shadow: 0 1px 6px #000;
}

#dialogContainer {
  user-select: none;
}

.hamburger {
  position: fixed;
  right: 8px;
  top: 11px;
  color: #fff;
  display: inline-block;
}

.hamburger div {
  display: inline-block;
}

.hue-slider::-webkit-slider-runnable-track {
  background: -webkit-linear-gradient(left,
      #ff0000 0%,
      #ffff00 17%,
      #00ff00 33%,
      #00ffff 50%,
      #0000ff 67%,
      #ff00ff 83%,
      #ff0000 100%);
  background: linear-gradient(to left,
      #ff0000 0%,
      #ffff00 17%,
      #00ff00 33%,
      #00ffff 50%,
      #0000ff 67%,
      #ff00ff 83%,
      #ff0000 100%);
  height: 3px;
}

.hue-slider::-moz-range-track {
  background: -moz-linear-gradient(to left,
      #ff0000 0%,
      #ffff00 17%,
      #00ff00 33%,
      #00ffff 50%,
      #0000ff 67%,
      #ff00ff 83%,
      #ff0000 100%);
  height: 3px;
}

.saturation-slider::-webkit-slider-runnable-track {
  background: -webkit-linear-gradient(left, #808080 0%, #ff0000 100%);
  background: -linear-linear-gradient(to left, #808080 0%, #ff0000 100%);
  height: 3px;
}

.saturation-slider::-moz-range-track {
  background: -moz-linear-gradient(left, #808080 0%, #ff0000 100%);
  height: 3px;
}

.brightness-slider::-webkit-slider-runnable-track {
  background: -webkit-linear-gradient(left, #000000 0%, #ffffff 100%);
  background: -linear-linear-gradient(left, #000000 0%, #ffffff 100%);
  height: 3px;
}

.brightness-slider::-moz-range-track {
  background: -moz-linear-gradient(left, #000000 0%, #ffffff 100%);
  height: 3px;
}

.alpha-slider::-webkit-slider-runnable-track {
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  background: -linear-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  height: 3px;
}

.alpha-slider::-moz-range-track {
  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  height: 3px;
}

.alpha-slider {
  width: 75px;
}

.prop-group-wide .hue-slider {
  width: 75px;
}

.prop-group-wide input[type="range"] {
  margin-top: 28px;
}

@supports (-ms-ime-align: auto) {
  .prop-group-wide input[type="range"] {
    margin-top: 22px;
  }
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

input[type="range"]::-ms-fill-lower {
  background-color: #46acd7;
}

input[type="range"]::-ms-fill-upper {
  background-color: #46acd7;
}

.blue2-slider::-webkit-slider-runnable-track {
  background: -webkit-linear-gradient(left, #fbdc0b 0%, #001eff 100%);
  background: -linear-linear-gradient(left, #fbdc0b 0%, #001eff 100%);
  height: 3px;
}

.blue2-slider::-moz-range-track {
  background: -moz-linear-gradient(left, #fbdc0b 0%, #001eff 100%);
  height: 3px;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background-color: transparent; */
  outline: none;
  margin-top: 9px;
}

@-moz-document url-prefix() {
  input[type="range"] {
    margin-top: 0px;
  }
}

/* 
input[type="range"]::-webkit-slider-thumb {
  z-index: 1;
  width: 12px;
  height: 14px;
  position: relative;
  top: -12px;
  background-image: url('./images/editor/slider-control.png');
  background-color: transparent;
  background-size: contain;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  z-index: 1;
  width: 12px;
  height: 14px;
  position: relative;
  top: -12px;
  background-image: url('./images/editor/slider-control.png');
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  height: 2px;
}

input[type="range"]::-webkit-slider-runnable-track:hover {
  cursor: pointer;
}

input[type="range"]::-moz-range-track {
  /*    background-color: #46acd7; */
/* height: 2px;
} */

/* .alpha-slider input[type="range"]::-webkit-slider-runnable-track {
  background-color: transparent
} */

input[type="range"]::-ms-thumb {
  background-image: url("./images/editor/slider-control.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 14px;
  z-index: 9999;
  display: block;
  background-color: transparent;
}

.right-ad-container {
  width: 172px;
  position: absolute;
  right: 0;
  background-color: #1b1f25;
  top: 40px;
  bottom: 0px;
  justify-content: center;
  display: flex;
  overflow-y: hidden;
}

.justify-center {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.bottom-ad-container {
  height: 102px;
  position: absolute;
  bottom: 0;
  background-color: #1b1f25;
  left: 250px;
  right: 172px;
}

.bottom-ad {
  width: 728px;
  height: 90px;
  margin: 6px auto;
}

.right-ad {
  width: 160px;
  height: 600px;
  align-self: center;
}

.right-pro {
  display: none;
}

@media (min-height: 690px) {
  .right-pro {
    margin-top: 10px;
    margin-left: 35px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
  }

  .right-pro img {
    height: 36px;
    display: inline-block;
    vertical-align: middle;
  }

  .right-pro span {
    color: #fff;
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
  }
}

.bottom-pro {
  display: none;
}

@media (min-width: 1340px) {
  .bottom-ad {
    margin: 6px 130px 6px auto;
  }

  .bottom-pro {
    position: absolute;
    right: 10px;
    top: 33px;
    display: block;
  }

  .bottom-pro img {
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }

  .bottom-pro span {
    color: #fff;
    display: inline-block;
    margin-left: 7px;
    margin-top: 2px;
    font-size: 15px;
    vertical-align: middle;
  }

  .bottom-pro .link {
    color: #3d7dec;
    display: block;
    margin-left: 0px;
    margin-top: 5px;
    font-size: 15px;
  }
}

@media (min-width: 1470px) {
  .bottom-ad {
    margin: 6px auto;
  }
}

.hamburgerProfile {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid !important;
}

.hamburgerProfile div {
  font-size: 16px;
}

.hamburgerProfile .userInfo {
  display: inline-block;
  text-align: left;
}

.userInfo .label {
  color: #ababab;
  margin-right: 10px;
}

.userInfo div {
  margin-left: 0;
}

.userInfo .loginType {
  display: flex;
  padding-bottom: 10px;
  color: #fff;
}

.userInfo .accountId {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
}

.userInfo .email {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 10px;
  color: #fff;
}

.userInfo .email .email-right:hover {
  color: #006dff;
}

.userInfo .email .email-left {
  display: flex;
  align-items: baseline;
}

.userInfo .email .email-right {
  cursor: pointer;
  display: flex;
}

.email-right:hover {
  color: #006dff;
}

.profile-public {
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-public span {
  cursor: pointer;
}

.profile-public span:hover {
  color: #006dff;
}

.hamburgerProfile .username {
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.hamburgerProfile .user-img {
  width: 90px;
  height: 90px;
  background-color: #eee;
  border-radius: 50%;
  background-size: cover;
  vertical-align: top;
  margin-top: 34px;
}

.proStatus {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.proStatus-left {
  display: flex !important;
  align-items: center;
}

.proStatus .proStatusCaption {
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.proStatus a {
  color: #fff;
  font-size: 16px;
}

.proStatus a:hover {
  color: #006dff;
}

.proStatus span {
  padding: 0 !important;
}

.proStatus div {
  display: block;
}

.proStatusPro {
  color: lawngreen !important;
  font-size: 16px !important;
  margin-left: 15px !important;
}

.divide-line {
  border-bottom: solid 1px #0e1012;
  width: 100%;
  margin-left: 0px !important;
}

.hamburgerMenu {
  text-align: left;
  border-radius: 8px;
  position: fixed;
  top: 64px;
  right: 15px;
  background-color: #1b1f25;
  border: #000000 solid 1px;
  width: 350px;
  cursor: default;
}

.hamburgerMenu span {
  display: block;
  padding: 10px;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
  color: white;
}

.accountMenu {
  position: fixed;
  top: 40px;
  right: 10px;
  width: 175px;
  background-color: #1b1f25;
  border-top: #484d53 solid 1px;
}

.accountMenu span {
  display: block;
  padding: 15px;
}

.accountMenu span:hover {
  color: #1965b1;
}

.loggedOutContainer {
  margin-top: 10px;
}

.cropMenu {
  text-align: center;
  display: inherit;
  width: 110px;
  margin-left: 0;
}

.cropMenus span {
  display: block;
  text-align: center;
}

.cropMenus {
  padding: 20px;
}

.cropRow {
  text-align: center;
  text-align: -webkit-center;
  display: inline-table;
}

.mainCropCancel {
  background-color: #576c89;
  border-radius: 24px;
  margin-top: 20px;
}

.mainCropFinish {
  background-color: #1976d2;
  border-radius: 24px;
  margin-top: 20px;
}

/*PRO Dialog */
.store-overlay {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  opacity: 0;
  transition: 0.3s;
  z-index: 1000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.store-overlay.active {
  top: 0;
  opacity: 0.8;
}

.store-dialog {
  position: fixed;
  top: -100%;
  left: 50%;
  width: 500px;
  height: 550px;
  margin-left: -410px;
  margin-top: -275px;
  background-color: #0d0f12;
  box-shadow: 0 0 30px #000;
  opacity: 0;
  transition: 0.3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1500;
}

.store-dialog.store-page {
  background-color: #fff;
}

.store-dialog.active {
  top: 50%;
  opacity: 1;
  margin-top: -305px;
}

.store-navbar {
  position: relative;
  line-height: 45px;
  background-color: rgb(19, 95, 181);
  color: #fff;
}

.store-navbar .title {
  text-align: center;
  font-size: 1.2em;
}

.store-navbar a {
  padding: 0 15px;
}

.store-navbar {
  margin-top: -45px;
}

.store-navbar a,
.store-navbar a:hover {
  color: #fff;
}

.store-navbar .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  background-image: url(//cdn.pizap.com/pizapfiles/images/close-icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
}

.store-navbar .back {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  padding-left: 30px;
  background-image: url(//cdn.pizap.com/pizapfiles/images/back-icon.png);
  background-position: 0 center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

.store-navbar .back.active {
  display: block;
}

.store-content {
  overflow: hidden;
  border: 6px #141517 solid;
  height: 598px;
}

.store-content .purchased {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #5fb404;
  color: #fff;
  border-radius: 5px;
}

.store-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  background-image: url(//cdn.pizap.com/pizapfiles/images/loading.gif);
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}

.store-loading.active {
  display: block;
}

.store-page {
  display: none;
}

.store-page.active {
  display: block;
}

.store-list {}

.store-list.active {
  display: block;
}

.store-list .list-item {
  position: relative;
}

.store-list .list-item:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.store-list .list-image {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 60px;
  height: 60px;
}

.store-list .list-image img {
  max-width: 100%;
  max-height: 100%;
}

.store-list .list-summary {
  margin-left: 90px;
  min-height: 60px;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  background-image: url(//cdn.pizap.com/pizapfiles/images/forward-icon.png);
  background-position: right center;
  background-repeat: no-repeat;
}

.store-list .list-summary.without-cur {
  background-image: none;
}

.store-list .list-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.store-list .list-purchase {
  position: absolute;
  top: 45px;
  right: 25px;
}

.store-list .list-sub-title {
  font-size: 0.9em;
  color: rgb(19, 95, 181);
  margin-bottom: 5px;
}

.store-list .list-description {
  color: #6e6e6e;
}

.store-list .list-description img {
  max-height: 45px;
  margin: 0 5px 0 0;
}

.store-overview {
  position: relative;
}

.store-overview.active {
  display: block;
}

.store-overview .overview-image {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 90px;
  height: 90px;
}

.store-overview-summary {
  margin-left: 105px;
  height: 90px;
  margin-bottom: 15px;
}

.store-overview .overview-description {
  color: #6e6e6e;
  margin-bottom: 15px;
}

.store-overview-summary {
  font-size: 1.2em;
}

.store-overview-summary .summary-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.store-overview-summary .summary-price {
  font-size: 0.9em;
  color: rgb(19, 95, 181);
}

.store-overview-summary .summary-purchase {
  position: absolute;
  top: 35px;
  right: 15px;
}

.store-overview .store-overview-list {
  overflow: hidden;
}

.store-overview .store-overview-list .list-item {
  position: relative;
  float: left;
  width: 61px;
  height: 61px;
  border-radius: 10px;
  background-color: rgb(29, 27, 28);
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid;
  border-color: #ccc;
}

.store-overview .store-overview-list img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.store-overview .summary-pay-btn {
  position: absolute;
  top: 30px;
  right: 15px;
}

.store-paystatus {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 210px;
  background-color: #fff;
  border-radius: 10px;
  display: none;
  z-index: 1005;
}

.store-paystatus.active {
  display: block;
}

.store-paystatus .waiting {
  color: #1467c2;
  font-size: 1.2em;
}

.store-paystatus .congrats {
  color: #5fb404;
  font-size: 1.2em;
}

.store-paystatus .big-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #1467c2;
  color: #fff;
}

.store-paystatus a {
  color: #1467c2;
}

.store-paystatus-content {
  padding: 15px;
  text-align: center;
}

.store-progress {
  height: 20px;
  background-color: #1467c2;
  background-image: url(//cdn.pizap.com/pizapfiles/images/progress.gif);
  border-radius: 5px;
  border: 1px solid #1467c2;
}

.store-page {
  color: #fff;
}

.store-overview {
  overflow: hidden;
}

.store-logo {
  height: 104px;
  background-image: url(./images/editor/pizap-pro-logo.png);
  background-repeat: no-repeat;
  width: 289px;
  margin: 0px 0px;
  background-size: 130px;
  background-position-y: 20px;
  background-position-x: 36px;
}

.store-page-trial {
  position: absolute;
  top: 20px;
  left: 0;
}

.store-slogan {
  font-size: 1.5em;
  text-align: center;
  margin: 30px 0;
}

.store-subscribe-description {
  text-align: center;
  font-size: 1.2em;
  width: 20em;
  margin: 30px auto;
}

.store-subscribe-btns {
  text-align: center;
  margin: 30px 0;
}

.store-subscribe-btn {
  position: relative;
  background-color: rgb(61, 109, 193);
  display: inline-block;
  border-radius: 0.2em;
  padding: 14px 20px;
  font-size: 1.2em;
  cursor: pointer;
}

.store-page-limited {
  padding: 0 15px;
  display: block;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.store-navbar {
  display: none;
}

.store-dialog {
  width: 820px;
  height: 610px;
  margin-left: -390px;
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
}

.store-overview-name {
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 -15px;
  padding: 15px;
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 15px;
  margin-bottom: 30px;
}

.store-background {
  background-color: #1b1f25;
  height: 82px;
}

.store-overview .overview-name-image {
  width: 2em;
  height: 2em;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 0.4em;
  border-radius: 0.15em;
}

.store-overview-list {
  width: 50%;
}

.store-option-container {
  text-align: center;
  text-align: -webkit-center;
}

.store-overview .overview-name-title {
  vertical-align: middle;
  display: inline-block;
}

.store-overview .overview-buy-options {
  overflow: hidden;
  padding-bottom: 7px;
  text-align: center;
  margin: 5px;
}

.store-overview .overview-buy-now {}

.pro-scroll-container {
  height: 510px;
  overflow-y: overlay;
  background-color: #0d0f12;
}

.store-overview .overview-buy-nowbtn {
  position: relative;
  display: inline-block;
  color: rgb(61, 109, 193);
  white-space: nowrap;
  width: 150px;
  height: 2em;
  text-align: center;
  line-height: 2em;
  vertical-align: middle;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.25em;
}

.store-overview .overview-buy-nowbtn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
}

.store-overview .overview-buy-nowbtn-txt {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 2em;
  display: block;
  z-index: 50;
}

.store-overview .overview-buy-nowbtn-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: block;
  opacity: 0;
}

.store-overview .overview-buy-pro {
  padding: 15px 0;
}

.store-overview .overview-buy-description {
  display: block;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
}

.store-overview .overview-buy-pro-btn {
  position: relative;
  background-color: rgb(61, 109, 193);
  display: inline-block;
  border-radius: 0.2em;
  padding: 14px 20px;
  font-size: 1.2em;
  cursor: pointer;
}

.store-subscribe-btn form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.store-subscribe-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
}

.store-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 35px !important;
  text-align: right;
  line-height: 1em;
  cursor: pointer;
  padding-right: 10px;
}

.store-close-btn:hover {
  color: #1467c2;
}

.overview-buy-now-or {
  display: none;
}

.store-unlock {
  text-align: center;
  padding: 20px;
  font-size: 0.8em;
  display: none;
}

.store_blue_button {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  text-align: center;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.store_blue_button a {
  color: #fff;
  text-decoration: none;
  background-color: #0068c2;
  padding: 15px;
  display: block;
  border-radius: 5px;
}

.store_blue_button a:visited {
  color: #fff;
  text-decoration: none;
  background-color: #0068c2;
  padding: 15px;
  border-radius: 5px;
}

.store_blue_button a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3589d2;
  padding: 15px;
  border-radius: 5px;
}

.store_blue_button a:active {
  color: #fff;
  text-decoration: none;
  background-color: #0068c2;
  padding: 15px;
  border-radius: 5px;
}

.store_green_button {
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.store_green_button a {
  color: #fff;
  text-decoration: none;
  background-color: #33906c;
  padding: 8px;
  border-radius: 5px;
  display: block;
}

.store_green_button a:visited {
  color: #fff;
  text-decoration: none;
  background-color: #33906c;
  padding: 8px;
  border-radius: 5px;
}

.store_green_button a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #68ab91;
  padding: 8px;
  border-radius: 5px;
}

.store_green_button a:active {
  color: #fff;
  text-decoration: none;
  background-color: #33906c;
  padding: 8px;
  border-radius: 5px;
}

.store_purple_button {
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.store_purple_button a {
  color: #fff;
  text-decoration: none;
  background-color: #7d26de;
  padding: 8px;
  border-radius: 5px;
  display: block;
}

.store_purple_button a:visited {
  color: #fff;
  text-decoration: none;
  background-color: #7d26de;
  padding: 8px;
  border-radius: 5px;
}

.store_purple_button a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #a367e5;
  padding: 8px;
  border-radius: 5px;
}

.store_purple_button a:active {
  color: #fff;
  text-decoration: none;
  background-color: #7d26de;
  padding: 8px;
  border-radius: 5px;
}

.store_sub_text {
  font-family: "PT Sans", sans-serif;
  font-size: 12px;
  color: #bbbbbb;
  text-align: center;
}

.white_heading {
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;

  padding-top: 0px;
  padding-bottom: 5px;
}

#savePhotoContainer {
  width: 200px;
  height: 150px;
  margin: 15px;
  float: left;
  text-align: center;
  padding-right: 20px;
}

#savePhotoContainer img {
  border: solid 1px;
  max-width: 100%;
  max-height: 100%;
}

#saveOptionContainer {
  color: #fff;
  margin: 15px;
  background-color: #121318;
}

#saveCaptionContainer {
  text-align: left;
}

#saveCaptionContainer textarea {
  width: 300px;
}

#saveCaptionContainer span {
  font-size: 16px;
  color: #fff;
}

#saveTopColumn {
  text-align: center;
  font-size: 13px;
}

.bold {
  font-weight: bold;
}

.image_quality {
  margin-left: 25px;
  margin-right: 10px;
}

.image_quality img {
  vertical-align: middle;
}

.save-dialog-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 710px;
  height: 390px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.share-dialog-box {
  border: solid 1px #66686b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 462px;
  height: 532px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 4;
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.image-share {
  height: 448px !important;
}

#saveDestinations {
  margin-left: 25px;
}

#finishSaveToPizap span {
  color: #fff;
  font-size: 30px;
  padding: 30px;
}

.saveDestination {
  height: 50px;
  color: #fff;
  float: left;
  vertical-align: middle;
  margin-top: 0px;
  position: relative;
  text-align: center;
  margin-right: 10px;
}

.saveDestination img {
  float: left;
  margin: 6px;
  margin-right: 0px;
}

.saveDestination button {
  width: 150px;
  cursor: pointer;
  background-color: #343f50;
  color: #fff;
  border: 0px;
  position: relative;
  outline: none;
}

.saveDestination button:active {
  border: 0px;
  outline: none;
}

.destinationName {}

.saveDestinationColumn {
  float: left;
  width: 25%;
}

.buttonProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  color: #00fe06;
}

progress[value] {
  /* Get rid of the default appearance */
  appearance: none;

  /* This unfortunately leaves a trail of border behind in Firefox and Opera. We can remove that by setting the border to none. */
  border: none;

  /* Add dimensions */
  width: 100%;
  height: 2px;

  /* Although firefox doesn't provide any additional pseudo class to style the progress element container, any style applied here works on the container. */
  background-color: black;

  /* Of all IE, only IE10 supports progress element that too partially. It only allows to change the background-color of the progress value using the 'color' attribute. */
  color: #00fe06;
}

/*
Webkit browsers provide two pseudo classes that can be use to style HTML5 progress element.
-webkit-progress-bar -> To style the progress element container
-webkit-progress-value -> To style the progress element value.
*/

progress[value]::-webkit-progress-bar {
  background-color: black;
}

progress[value]::-webkit-progress-value {
  background: #00fe06;
}

progress[value]::-moz-progress-bar {
  background: #00fe06;
}

.saveCheck {
  position: absolute;
  right: 15px;
  top: 4px;
  height: 15px;
  width: 15px;
}

.shareMenu {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.53);
  -moz-box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.53);
  box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.53);
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
  margin-top: -4px;
}

.shareMenu h2 {
  color: #000;
  font-size: 18px;
}

.shareMenu a:link {
  color: #3c4046;
  display: block;
  padding: 9px;
  text-decoration: none;
  border-bottom: 1px solid #dadada;
}

.shareMenu a:visited {
  color: #3c4046;
  display: block;
  padding: 9px;
  text-decoration: none;
  border-bottom: 1px solid #dadada;
}

.shareMenu a:hover {
  color: #3c4046;
  display: block;
  background-color: #dcdcdc;
  padding: 9px;
  text-decoration: none;
  border-bottom: 1px solid #dadada;
}

.shareMenu a:hover .top {
  -webkit-border-top-left-radius: 7px;
  -webkit-border-top-right-radius: 7px;
  -moz-border-radius-topleft: 7px;
  -moz-border-radius-topright: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.shareMenu a:active {
  color: #3c4046;
  display: block;
  padding: 9px;
  text-decoration: none;
  border-bottom: 1px solid #dadada;
}

#imageQualityContainer {
  margin-top: 15px;
}

.embedLink {
  z-index: 1003;
  width: 410px;
  height: 250px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.embedEmail {
  z-index: 1003;
  width: 410px;
  height: 350px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.embedLink span {
  position: absolute;
  right: 12px;
  top: 5px;
  color: #000;
  cursor: pointer;
}

.embedLink textarea {
  height: 155px;
  width: 390px;
}

.saveDoneBtns button {
  border-radius: 24px;
  padding: 10px 17px;
  background: #006dff;
  width: 160px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.saveDoneBtns {
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.download-message {
  text-align: center;
  position: absolute;
  bottom: 38px;
  left: 0;
  right: 0;
  font-size: 16px;
}

.download-message2 {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 16px;
}

.downloads {
  color: #cc6600;
}

.saveDoneBtns span {
  color: #fff;
  margin-right: 20px;
}

.embedEmail span {
  color: #fff;
  margin-left: 20px;
  margin-right: 10px;
}

.embedEmail input {
  margin-right: 20px;
  width: 320px;
}

.embedEmail textarea {
  resize: none;
  margin-left: 20px;
  margin-right: 20px;
  width: 90%;
  height: 100px;
}

.sendEmail {
  height: 38px;
  width: 140px;
  background-color: #006dff;
  color: #fff;
  font-size: 16px;
  border-radius: 24px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}

.spinnerDialog {
  z-index: 1003;
  width: 210px;
  height: 185px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #272a32;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.customSizeDialog {
  z-index: 1003;
  width: 410px;
  height: 185px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 2;
  border-radius: 10px;
}

.customSizeContainer {
  margin-top: 30px;
  color: #fff;
}

#units {
  margin-left: 20px;
}

.customSizeOK {
  background-color: #1976d2;
  border-radius: 24px;
  margin-top: 25px;
  width: 100px;
  height: 40px;
}

.saveQualityDialog {
  border: solid 1px #66686b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 426px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 4;
  border-radius: 8px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.pro-overlay {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 20px !important;
  width: 20px !important;
}

.font-selector .pro-overlay {
  left: 0px;
}

.paint-container .icon-row {
  margin-top: 20px;
}

.paint-container .color-picker {
  padding-top: 20px;
}

.start-search {
  line-height: 45px;
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
  width: 400px;
  padding-right: 25px;
}

.start-search .searchIcon {
  left: 6px !important;
}

.start-search .start__clear__icon {
  right: 32px !important;
}

.react-autosuggest__suggestions-container--open {
  z-index: 20000 !important;
}

.menu-container .google-search {
  top: 132px !important;
}

.menu-container .google-search .searchIcon {
  left: 30px !important;
}

.google-search {
  position: absolute;
  top: 72px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.google-search ul {
  text-align: left;
}

.start-search input {
  width: 400px !important;
}

.google-search input {
  margin-left: 0px !important;
  width: 250px;
  font-size: 16px;
  background-color: #fff;
  padding-left: 30px;
}

.google-search .searchIcon {
  position: absolute;
  left: 96px;
  top: 7px;
  height: 17px;
  width: 17px;
}

.graphicsButtons {
  padding: 20px;
}

.graphicsButtons .upload span {
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: bottom;
  color: #eaebf0;
  font-size: 14px;
}

.graphicsButtons .upload {
  border-radius: 24px;
  padding: 10px;
  background: #006dff;
  margin: auto;
}

.google-search .a {
  fill: #aaa !important;
}

.google-search img {
  position: inherit;
  vertical-align: middle;
  margin-left: 6px;
  background-color: #1976d2;
  border-radius: 0.3em;
  padding: 6px;
  height: 16px;
}

.leftTooltip {
  position: absolute;
  color: #fff;
  width: 100px;
  top: 0;
  right: 50px;
  z-index: -1;
  transition: 0.3s ease;
}

.stickerContainer {
  position: absolute;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 165px;
}

.borderContainer {
  overflow-y: auto;
  width: 300px;
  top: 60px !important;
}

.borderCustom {
  padding: 20px;
  overflow-y: auto;
}

.effectContainer {
  padding: 20px;
}

.touchupContainer {
  padding: 20px;
}

.touchupContainer span {
  display: block;
  color: #fff;
}

.touchupContainer input {
  margin-bottom: 20px;
}

.cutout-style {
  display: inline-block;
  width: 33%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cutout-style label {
  margin-top: 20px !important;
  display: block;
  margin: 10px;
}

.cutout-style img {
  width: 175px;
  margin-top: 50px;
  cursor: pointer;
}

.cutout-style span.desc {
  font-size: 13px;
  color: #b7b7b7;
}

.cutout-style-dialog button {
  margin-left: 20px;
  margin-top: 30px;
  margin-right: 20px;
}

.cutoutSkipBtn {
  background-color: #555a67;
  border-radius: 24px;
  width: 110px;
}

.cutoutZoomBtn {
  background-color: #1976d2;
  border-radius: 24px;
  margin-top: 50px;
  width: 110px;
}

.font-selector {
  position: relative;
}

.qualitybox {
  width: 125px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: 10px;
  position: relative;
}

.qualitybox img {
  margin-right: 10px;
}

.qualitysize {
  margin-top: 10px;
  display: block;
  color: #80858d;
}

.qualitytext {
  font-size: 14px;
  vertical-align: super;
}

.qualitycontainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  text-align: center;
}

.qualitywrap {
  margin-top: 6px;
}

.savelabel {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}

.filename {
  text-align: center;
}

.filename input {
  width: 250px;
}

.filename select {
  height: 29px;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.customSize {
  /* margin-left: 50px; */
}

.customSize input {
  width: 60px;
}

.customSize span {
  margin-left: 10px;
  margin-right: 10px;
}

.touchupContainer .reset {
  color: #b0b0b0;
}

.mainButtons {
  height: 60px;
}

.text-holder {
  height: 120px;
}

.bubble-holder {
  height: 150px;
}

.accordion-category {
  background-color: #252a32;
  border-top: #484d54 solid 1px;
  border-bottom: #1b1f26 solid 2px;
  height: 40px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  width: 300px;
  color: #fff;
  position: relative;
  font-size: 14px;
}

.accordion-category:hover {
  background-color: #20242b;
  color: #1b6cc2;
}

.accordion-category:hover svg path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.accordion-category img {
  max-height: 30px;
  max-width: 30px;
  vertical-align: middle;
}

.accordion-category svg {
  max-height: 30px;
  max-width: 30px;
  vertical-align: middle;
}

.accordion-category span {
  vertical-align: middle;
}

.accordion-category .arrowright {
  position: absolute;
  height: 12px;
  right: 10px;
  top: 15px;
}

.accordion-category .arrowdown {
  position: absolute;
  right: 9px;
  top: 15px;
  width: 12px;
}

.accordion-category .image-wrapper {
  width: 58px;
  display: inline-block;
  text-align: center;
}

.accordion-sub-category {
  background-color: #191c21;
  height: 33px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  width: 300px;
  color: #fff;
}

.accordion-sub-category:hover {
  background-color: #15181e;
  color: #1b6cc2;
}

.accordion-sub-category img {
  max-height: 25px;
  max-width: 25px;
  vertical-align: middle;
  margin-right: 15px;
  margin-left: 30px;
}

.accordion-sub-category span {
  vertical-align: middle;
}

.accordion-sub-category .image-wrapper {
  width: 70px;
  display: inline-block;
  text-align: center;
}

.accordion-sub-category-container {
  width: 260px;
}

.accordion {
  overflow-x: hidden;
}

.first {
  margin-top: 10px !important;
}

.loginPage {}

.browserPage {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.loginPage .colorBarContainer {
  background-color: #15181e;
  padding-top: 54px;
}

.colorBar {
  position: relative;
  bottom: 0;
  height: 2px !important;
  margin-top: 7px;
}

.browserPage .floatLogo .colorBar {
  height: 15px !important;
}

.browserPage .floatLogo .colorBar div {
  height: 15px !important;
}

.loginPage .floatLogo .colorBar {
  height: 15px !important;
}

.loginPage .floatLogo .colorBar div {
  height: 15px !important;
}

.loginPage .colorBar {
  height: 10px !important;
}

.loginPage .colorBar div {
  height: 10px !important;
}

.store-overview .colorBar {
  height: 6px !important;
}

.loginColumnsWrapper {
  margin: auto;
  padding-top: 102px;
  text-align: center;
}

.loginContainer {
  height: 100%;
  background-image: url("//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_background.f51f5035.jpg");
  background-size: cover;
  position: absolute;
  top: 54px;
  bottom: 0;
  left: 0;
  right: 0;
}

.loginColumns .login-main-btns .loginMainButtonContainer {
  margin-top: 23px;
  padding-top: 32px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  width: 302px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 21px;
}

.recentLoginDetails {
  display: inline-block;
  padding-left: 8px;
  font-size: 17px;
  position: relative;
  top: -20px;
  left: 0;
}

.recentLoginDetails div {
  padding-bottom: 5px;
}

.recentLoginDetails div:nth-child(3) {
  padding-bottom: 0px;
}

.recentLoginDetails i {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-size: contain;
  margin-right: 15px;
  background-repeat: no-repeat;
  margin-top: 5px;
}

.recentLoginPro {
  display: inline-block;
  height: 39px;
  padding-right: 25px;
  vertical-align: top;
  float: right;
  margin-top: -22px;
}

.recentLogin {
  display: flex;
  padding-top: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  text-align: left;
  padding-left: 25px;
  margin-bottom: 10px;
}

.recentLogin .user-img {
  width: 50px;
  height: 50px;
  margin-top: -14px;
  vertical-align: top;
}

.loginColumns {
  color: #fff;
  width: 50%;
  display: inline-block;
  text-align: center;
  margin: auto;
  vertical-align: top;
}

@media screen and (max-width: 884px) {
  .loginColumns {
    width: 100% !important;
  }
}

.recentLoginContainer {
  margin: auto;
  margin-top: 30px;
  width: 302px;
  max-height: 500px;
}

.loginColumns a.btn {
  width: 229px;
  height: 25px;
  border-radius: 8px;
}

.loginColumns .icon {
  position: absolute;
  left: 10px;
  height: 36px;
  width: 24px;
  top: 2px;
}

.loginHeading {
  font-size: 24px;
}

.loginSelectAccount {
  margin-top: 20px;
  font-size: 18px;
}

.loginColumns .loginSelectAccount {
  font-size: 20px;
  margin-top: 25px;
}

@media (min-width: 1300px) {
  .loginContainer {
    background-image: url("//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_background.f51f5035.jpg");
  }
}

@media (min-width: 2000px) {
  .loginContainer {
    background-image: url("//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_background.f51f5035.jpg");
  }
}

.browserMessage {
  padding-top: 220px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.browserItem {
  padding-bottom: 30px;
}

.browserItems {
  padding-top: 40px;
  align-content: center;
}

.browserLogo {
  height: 60px;
  vertical-align: middle;
}

.browserItem span {
  width: 250px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 40px;
}

.downloadButton {
  height: 40px;
  vertical-align: middle;
}

.floatLogo {
  position: absolute;
  top: 12px;
  left: 0;
  margin: auto;
  right: 0;
  z-index: 12;
  text-align: center;
  z-index: 10;
}

.floatLogo img {
  margin-top: 0px;
  height: 84px;
  max-width: 84px;
  cursor: pointer;
}

.floatLogo .colorBar {
  padding-top: 19px;
}

.colorBar div {
  width: 20%;
  float: left;
  height: 2px;
}

.store-overview .colorBar div {
  height: 6px !important;
}

.orange {
  background-color: #ef6c00;
}

.yellow {
  background-color: #ffa726;
}

.green {
  background-color: #00897b;
}

.lightBlue {
  background-color: #00b0ff;
}

.blue {
  background-color: #1976d2;
}

.feature-item:nth-child(1) {
  margin-top: 30px;
}

.orangeText {
  color: #ffa726;
}

.feature-item {
  background-repeat: no-repeat;
  background-size: 32px;
  background-position-y: 2px;
  padding-left: 47px;
  padding-right: 15px;
  margin-bottom: 10px;
  margin-left: 30px;
  text-align: left;
}

.feature-item h3 {
  font-weight: bold;
  font-size: 16px;
}

.feature-item span {
  color: #ccc;
  font-size: 11px;
}

.overview-buy-options input {
  margin-left: 30px;
  margin-right: 30px;
  height: 100%;
  vertical-align: middle;
}

.pro-description {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

.pro-description h3 {
  margin-top: 46px;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin-bottom: 28px;
}

.pro-annual {
  position: relative;
  height: 210px;
  border: 1px #ef6c00 solid;
  margin: 10px;
  width: 265px;
  background-color: #1b1f25;
  text-align: center;
  display: inline-block;
}

.pro-monthly {
  position: relative;
  border: 1px #33906c solid;
  height: 210px;
  margin: 10px;
  width: 265px;
  background-color: #1b1f25;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.pro-lifetime {
  height: 77px;
  border: 1px #00897b solid;
  margin: 10px;
  background-color: #1b1f25;
  text-align: left;
}

.annual-deal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ef6c00;
  color: #fff;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.pro-overview-text {
  font-size: 18px;
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
}

.annual-deal span {
  display: block;
  font-size: 14px;
}

.free-trial {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  background-color: #0d0f12;
  padding-top: 20px;
  padding-bottom: 20px;
}

.learn-more {
  background-color: #0064c5;
  color: #ffffff;
  margin-top: 10px;
  height: 30px;
}

.start-trial {
  width: 200px;
  height: 50px;
  font-size: 18px;
  background-color: #0064c5;
  color: #ffffff;
  margin-top: 2px;
  margin-bottom: 10px;
}

.pro-price {
  font-size: 32px;
}

.effectContainer .photopicker-list-item img {
  max-width: 103px !important;
}

.pro-teaser {
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  top: 6px;
  height: 32px;
  display: flex !important;
  align-items: center;
}

.overview-buy-options .btn {
  position: relative;
  width: 250px;
  height: 40px;
}

.overview-buy-options i {
  height: 32px;
  position: absolute;
  left: 20px;
  top: 4px;
}

#sign-in-username {
  margin-left: 0;
}

#sign-in-password {
  margin-left: 0;
}

.suggestions {}

.feedback-img {
  height: 100px;
  margin: 10px;
}

.shareDoneBtns {
  bottom: 20px;
}

.no-support {
  margin: auto auto;
  width: 453px;
  min-width: initial;
  min-height: initial;
  font-size: 18px;
  color: #fff;
  line-height: 2;
  height: 300px !important;
  vertical-align: middle;
  margin-top: 15%;
}

.rotate-slider {
  transform: rotateY(180deg);
}

.fontIncrement {
  margin-left: 5px;
  font-size: 17px;
  width: 50px;
  margin-top: 21px;
  background-color: #1b1f25 !important;
  color: #fff !important;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
}

.font-yours {
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
  padding-top: 10px;
  height: 26px;
  width: 200px;
  overflow-x: hidden;
}

.textControls {
  text-align: left;
}

.textControls .color-picker {
  width: 45px;
}

.color-picker-horizontal {
  width: 240px !important;
  height: 42px;
  position: relative;
}

.color-picker-horizontal input[type="checkbox"] {
  position: absolute;
  top: 10px;
  left: 1px;
}

.color-picker-horizontal span {
  position: absolute;
  top: 10px;
  left: 30px;
}

.color-picker-horizontal .preview {
  position: absolute;
  top: 5px;
  left: 110px;
}

.colorCodeManual span {
  position: inherit !important;
}

.font-category img {
  width: 250px;
}

.font-category:hover {
  background-color: #0d0f12;
}

.font-category.selected {
  background-color: #616a77;
}

.font-item {
  height: 45px;
  width: 230px !important;
  background-size: contain;
  position: relative;
}

.font-item .pro-overlay {
  left: 2px;
  right: inherit;
}

.font-item:hover {
  background-color: #006dff;
  background-blend-mode: lighten;
}

.fonts-container {
  padding-top: 4px;
  position: absolute;
  border-radius: 6px;
  left: 263px;
  display: flex;
  height: 455px;
  overflow-x: hidden;
  background-color: #fff;
  flex-wrap: wrap;
  align-content: flex-start;
}

.yourfonts-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  float: left;
  height: 455px;
  overflow: auto;
  width: 706px;
  background-color: #fff;
}

.scrollContainer {
  overflow-y: auto;
  position: absolute;
  top: 46px;
  bottom: 0;
}

@-moz-document url-prefix() {
  .prop-group-wide input[type="range"] {
    margin-top: 14px;
  }

  .prop-group-wide .hue-slider {
    width: 63px;
  }

  .alpha-slider {
    width: 63px;
  }

  .prop-blend select {
    width: 67px;
  }
}

.adjust-container {
  top: 280px !important;
  width: 300px;
  overflow-x: hidden;
}

.effectRemoveContainer {
  top: 124px !important;
}

.stickerMenuContainer {
  top: 180px !important;
  width: 300px;
  overflow-x: hidden;
}

.sticker-scroll {
  font-size: 13px;
  text-align: left;
}

.sticker-scroll .sticker-scroll-name {
  padding-right: 10px;
  font-weight: bold;
  color: #ddd;
}

.sticker-scroll-topbar {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  height: 20px;
}

.sticker-scroll-items-row .leftScroll {
  cursor: pointer;
  margin: auto;
  border-radius: 30px;
  padding: 8px 12px;
  background: #006dff;
  float: left;
  width: 52px;
}

.sticker-scroll-items-row {
  height: 60px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  display: flex;
}

.sticker-scroll-items-row .sticker-scroll-items {
  display: flex;
  align-items: center;
}

.sticker-item {
  width: 55px;
}

.sticker-scroll_items-overflow {
  padding: 5px;
  display: flex;
  scrollbar-width: 0px;
  counter-reset: item;
  align-items: center;
}

.sticker-scroll_items-overflow .sticker-item {
  position: relative;
}

.sticker-scroll_items-overflow .pro-overlay {
  top: 0;
  left: unset;
  right: 0px;
  height: 15px !important;
  width: 15px !important;
}

.sticker-scroll_items-overflow::-webkit-scrollbar {
  display: none;
}

.sticker-scroll-items-row .rightScroll {
  cursor: pointer;
  margin: auto;
  border-radius: 30px;
  padding: 8px 12px;
  background: #006dff;
  float: right;
  width: 52px;
}

.sticker-scroll-items-row svg {
  cursor: pointer;
}

.sticker-scroll-seeall {
  float: right;
}

.effectContainer.effectMainMenu {
  top: 56px !important;
}

.effectContainer.effectRemoveContainer.effectMainMenu {
  top: 92px !important;
}

.textTypeContainer {
  padding: 20px;
}

.textTypeContainer h2 {
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
}

.borderContainer.borderMainMenu {
  top: 84px !important;
}

.borderContainer.borderRemoveContainer {
  top: 123px !important;
}

.borderContainer.borderRemoveContainer.borderMainMenu {
  top: 81px !important;
}

.memeContainer {
  top: 46px !important;
  overflow-x: hidden;
  width: 100%;
}

.googleStickers {
  background-color: #fff;
}

.main-profile-image {
  margin: auto;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  box-shadow: unset;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.main-profile-image .profile-name {
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  font-size: 17px;
  padding-left: 0px;
}

.done-btn {
  background: #006dff;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#editor-properties,
#editor-properties-context {
  display: none;
  border: 1px solid #252a31;
  background-color: #1b1f25;
  border-radius: 8px;
  padding-bottom: 15px;
}

.remove-border-btn {
  margin: auto;
  width: 116px;
  margin-bottom: 10px;
}

.remove-btn {
  background-color: #ff0000;
  color: #fff;
  border-radius: 30px;
  width: 110px;
  border: 0;
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
}

.remove-btn:hover {
  color: #111419 !important;
}

.add-your-own {
  background-color: #111419;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
}

.your-cutouts {
  background-color: #111419;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
}

.windows-download {
  height: 20px;
  padding-left: 5px;
  vertical-align: bottom;
}

.property-mover {
  height: 20px;
  background-color: #252a32;
  border-radius: 4px 4px 1px 1px;
}

.centerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 445px;
  height: 415px;
  overflow: hidden;
  z-index: 2;
  color: #fff;
  text-align: center;
}

.touse {
  margin-top: 60px;
  margin-bottom: 20px;
}

.design-category {
  background-color: #252a32;
  border-top: #484d54 solid 1px;
  border-bottom: #1b1f26 solid 2px;
  height: 40px;
  text-align: left;
  display: block;
  vertical-align: middle;
  width: 300px;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.design-category svg {
  max-height: 22px;
  max-width: 23px;
  margin-top: 11px;
  margin-left: 18px;
}

.design-category span {
  left: 60px;
  position: absolute;
  top: 11px;
}

.design-category .color-picker {
  left: 10px;
  position: absolute;
  top: -3px;
  width: 37px;
}

.design-category .color-picker span {
  top: 14px;
  width: 150px;
  left: 51px;
}

.design-category:hover {
  background-color: #20242b;
  color: #1b6cc2;
}

.design-category:hover svg path {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.transparent input[type="checkbox"] {
  transform: scale(2);
  position: absolute;
  top: 12px;
  left: 20px;
}

.canvas-size svg {
  margin-left: 18px;
}

.touchup-zoom {
  width: 268px;
  height: 44px;
  background: rgba(28, 31, 38, 0.7);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  padding: 8px 20px;
  display: inline-block;
  vertical-align: top;
  z-index: 2;
}

.touchup-zoom-container {
  position: absolute;
  bottom: 15px;
  left: 50%;
  right: 50%;
  margin-left: -131px;
  width: 515px;
  z-index: 2;
}

.compare-container {
  line-height: 42px;
  width: 120px;
  height: 60px;
  background: rgba(28, 31, 38, 0.7);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  vertical-align: middle;
  z-index: 2;
}

.compare-container span {
  padding-top: 15px;
  padding-left: 15px;
  vertical-align: middle;
  display: inline-block;
}

.compare-container svg {
  padding-top: 15px;
  padding-left: 15px;
  vertical-align: middle;
}

.pro .touchup-zoom-container {
  bottom: 15px;
  margin-left: -60px;
  display: flex;
}

.filter-zoom-container {
  position: absolute;
  bottom: 15px;
  left: 50%;
  right: 50%;
  margin-left: -131px;
  width: 570px;
  z-index: 2;
}

.background-zoom {
  width: 380px;
  background: rgba(28, 31, 38, 0.7);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  vertical-align: middle;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 0px;
}

.background-zoom .blue-slider {
  width: 244px !important;
}

.pro .filter-zoom-container {
  bottom: 15px;
}

.compare-position {
  position: absolute;
  bottom: 15px;
  left: 50%;
  right: 50%;
  width: 120px;
}

.pro .compare-position {
  margin-left: 121px;
  bottom: 15px;
  z-index: 1;
}

.background-zoom-flip {
  display: inline-block;
  padding-left: 20px;
}

.background-zoom-rotate {
  display: inline-block;
  padding-left: 20px;
}

.background-compare {
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.background-zoom span {
  padding-right: 20px;
}

.background-zoom .reset {
  position: absolute;
  top: 5px;
  left: 275px;
}

.filter-strength .reset {
  position: absolute;
  top: 15px;
  left: 375px;
}

.filter-strength .blue-slider {
  width: 226px !important;
}

.prop-group-wide .reset {
  position: absolute;
  right: -9px;
  top: 0;
}

.collage-color {
  color: #fff;
  font-size: 14px;
  text-align: left;
  position: relative;
}

.collage-color .color-picker {
  width: 50px;
}

.collage-color .category-name {
  position: absolute;
  top: 15px;
  left: 58px;
}

.collage-spacing {
  background-color: #252a32;
  height: 70px;
  text-align: left;
  vertical-align: middle;
  width: 300px;
  color: #fff;
  position: relative;
  font-size: 14px;
  border: 0;
  display: block;
  padding-top: 10px;
}

.collage-range {
  padding-top: 15px;
  padding-left: 15px;
}

.collage-hover-dialog {
  z-index: 1;
  position: absolute;
  display: none;
}

.round-button {
  background-color: rgba(37, 42, 50, 0.65);
  border-radius: 8px;
  height: 38px;
  margin-right: 10px;
}

.collageActive {
  display: block;
}

.left-side {
  width: 47%;
  padding: 60px 30px;
  color: #fff;
}

.left-side .title {
  font-size: 15px;
}

.hidden {
  display: none !important;
}

.mt-5 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.pro-description {
  display: flex;
  margin-top: 30px;
}

.pro-description span {
  margin-left: 15px;
  font-size: 15px;
}

.left-side .try-text {
  font-size: 26px;
  font-weight: bold;
}

.pizap-large {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
}

.trial-dialog {
  width: 98%;
  max-width: 1000px;
  height: 90%;
  max-height: 564px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: solid 1px #66686b;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.trial-dialog .close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  background: #3937379c;
  border-radius: 50%;
}

.pro-get-dialog {
  position: relative;
  top: 50%;
  width: 350px;
  height: 450px;
  margin: auto;
  background-color: #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1500;
  padding: 8px;
  margin-top: -225px;
}

.pro-get-dialog .inner-container {
  height: 450px;
  background-color: #0d0f12;
}

.pro-get-dialog .colorBar div {
  height: 6px;
}

.pro-get-dialog .logo {
  padding-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pro-get-dialog .store-close-btn {
  top: 10px;
  right: 10px;
}

.largeHeading {
  font-family: "PT Sans", sans-serif;
  font-size: 44px;
  color: #fff;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}

.pro-get-dialog .normalText {
  padding-left: 52px;
  text-align: center;
  color: #fff;
  padding-right: 52px;
  padding-top: 25px;
  padding-bottom: 10px;
}

.store-overview .normalText {
  padding-left: 52px;
  text-align: center;
  color: #fff;
  padding-right: 52px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 21px;
}

.freeTrialButton {
  text-align: center;
  margin-top: 20px;
}

.freeTrialButton button {
  padding: 16px;
  color: #fff;
  background-color: #0064c5;
  font-size: 16px;
  border-radius: 3px;
  border: none;
}

.store-overview .largeHeading {
  font-size: 32px;
}

.store-overview .login-main-btns {
  background-color: #0d0f12;
}

.store-overview .login-main-register {
  background-color: #0d0f12;
}

.store-overview .login-main-email {
  background-color: #0d0f12;
}

.store-overview .login-main-forgot {
  background-color: #0d0f12;
}

.annual-try {
  background-color: #0064c5;
  color: #ffffff;
  margin-top: 20px;
  height: 32px !important;
  width: 70px !important;
  margin-right: 20px;
}

.annual-buy {
  background-color: #1b1f25;
  color: #ffffff;
  margin-top: 20px;
  height: 32px !important;
  width: 70px !important;
  border: 1px #ef6c00 solid;
}

.monthly-buy {
  background-color: #1b1f25;
  color: #ffffff;
  margin-top: 20px;
  height: 32px !important;
  width: 70px !important;
  border: 1px #33906c solid;
}

.fontFlashBlocked {
  text-align: center;
  width: 100%;
  height: 100%;
}

.fontFlashBlocked .largeHeading {
  color: #000;
  margin-top: 90px;
  margin-bottom: 20px;
}

.fontFlashBlocked p {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
}

.fontFlashBlocked .btn {
  background-color: #0064c5;
  color: #ffffff;
  padding: 15px;
  width: 200px;
  font-size: 24px;
}

@media (min-width: 1100px) {
  .shareBtn span {
    display: inline;
  }

  .saveBtn span {
    display: inline;
  }

  .undoBtn span {
    display: inline;
  }

  .redoBtn span {
    display: inline;
  }
}

.login-dialog .largeHeading {
  font-size: 36px;
}

.login-dialog .normalText {
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.saveLogin .login-main-btns {
  height: 287px;
}

.pro-get-save .normalText {
  font-size: 14px;
}

.pixabaySearch {
  padding-top: 10px;
}

.pixabaySearch input {
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  width: 160px;
}

.searchBtn {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-image: url(./images/editor/search.svg);
  background-color: #0064c5;
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: 7px;
  vertical-align: top;
  border-radius: 8px;
}

.searchTextHeader {
  color: #ffffff;
  font-size: 37px;
  font-weight: normal;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 40px;
  position: relative;
  height: 27px;
}

.searchTextHeader img {
  width: 94px !important;
  height: 55px !important;
  right: 24px;
  position: absolute;
  top: -21px;
}

.searchTextHeader svg {
  right: 24px;
  position: absolute;
  top: -21px;
}

.google-search .pro-overlay {
  background-color: inherit;
}

.qualitywrap img {
  position: absolute;
  top: -10px;
  right: -20px;
  height: 30px;
  width: 30px;
}

.feedback-instructions {
  display: inline-block;
  width: 410px;
}

.video-js {
  width: 480px;
  height: 270px;
  margin-top: 62px;
  display: inline-block !important;
}

.start-new-menu {
  position: fixed;
  top: 40px;
  background-color: #1b1f25;
  border-top: #484d53 solid 1px;
  color: #eaebf0;
  font-size: 15px;
  margin-left: -110px !important;
}

.start-new-menu-item {
  display: block !important;
  padding: 5px 15px 5px 0px;
  text-align: left;
  margin-left: 10px !important;
}

.start-new-menu-item:hover {
  color: #1965b1;
}

.start-new-menu-item span {
  padding-left: 0px !important;
  vertical-align: middle !important;
}

.start-new-menu-item svg {
  vertical-align: middle !important;
}

.start-new-menu-item:hover .a {
  fill: #1976d2;
  fill-opacity: 0.9922;
}

.start-new-menu-item:hover .d {
  stroke: #1976d2;
}

.start-new-menu-item:hover span {
  color: #1976d2;
}

.vjs-tech {
  width: 480px !important;
  height: 270px !important;
}

.video-js .vjs-fullscreen-control {
  display: none;
}

.video-js .vjs-big-play-button {
  top: 131px !important;
  left: 197px !important;
  display: none !important;
}

.brushHolder {
  text-align: center;
  height: 50px;
}

/**
 * Copyright 2014 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ima-ad-container {
  top: 0px;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

/* Move overlay if user fast-clicks play button. */
.video-js.vjs-playing .bumpable-ima-ad-container {
  margin-top: -40px;
}

/* Move overlay when controls are active. */
.video-js.vjs-user-inactive.vjs-playing .bumpable-ima-ad-container {
  margin-top: 0px;
}

.video-js.vjs-paused .bumpable-ima-ad-container,
.video-js.vjs-playing:hover .bumpable-ima-ad-container,
.video-js.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
  margin-top: -40px;
}

.ima-controls-div {
  bottom: 0px;
  height: 37px;
  position: absolute;
  overflow: hidden;
  display: none;
  opacity: 1;
  background-color: rgba(7, 20, 30, 0.7);
  background: -moz-linear-gradient(bottom,
      rgba(7, 20, 30, 0.7) 0%,
      rgba(7, 20, 30, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0%, rgba(7, 20, 30, 0.7)),
      color-stop(100%, rgba(7, 20, 30, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom,
      rgba(7, 20, 30, 0.7) 0%,
      rgba(7, 20, 30, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom,
      rgba(7, 20, 30, 0.7) 0%,
      rgba(7, 20, 30, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom,
      rgba(7, 20, 30, 0.7) 0%,
      rgba(7, 20, 30, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to top,
      rgba(7, 20, 30, 0.7) 0%,
      rgba(7, 20, 30, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0007141E',
      endColorstr='#07141E', GradientType=0);
  /* IE6-9 */
}

.ima-countdown-div {
  height: 10px;
  color: #ffffff;
  text-shadow: 0 0 0.2em #000;
  cursor: default;
}

.ima-seek-bar-div {
  top: 12px;
  height: 3px;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
}

.ima-progress-div {
  width: 0px;
  height: 3px;
  background-color: #ecc546;
}

.ima-play-pause-div,
.ima-mute-div,
.ima-slider-div,
.ima-fullscreen-div {
  width: 35px;
  height: 20px;
  top: 11px;
  left: 0px;
  position: absolute;
  color: #cccccc;
  font-size: 1.5em;
  line-height: 2;
  text-align: center;
  font-family: VideoJS;
  cursor: pointer;
}

.ima-mute-div {
  left: auto;
  right: 85px;
}

.ima-slider-div {
  left: auto;
  right: 35px;
  width: 50px;
  height: 10px;
  top: 20px;
  background-color: #555555;
}

.ima-slider-level-div {
  width: 100%;
  height: 10px;
  background-color: #ecc546;
}

.ima-fullscreen-div {
  left: auto;
  right: 0px;
}

.ima-playing:before {
  content: "\00f103";
}

.ima-paused:before {
  content: "\00f101";
}

.ima-playing:hover:before,
.ima-paused:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-muted:before {
  content: "\00f107";
}

.ima-muted:before {
  content: "\00f104";
}

.ima-non-muted:hover:before,
.ima-muted:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-fullscreen:before {
  content: "\00f108";
}

.ima-fullscreen:before {
  content: "\00f109";
}

.ima-non-fullscreen:hover:before,
.ima-fullscreen:hover:before {
  text-shadow: 0 0 1em #fff;
}

.paint-tool img {
  height: 57px;
  padding-left: 10px;
  padding-right: 10px;
}

.touchupApplyBtn {
  background-color: #006dff;
  border-radius: 20px;
  margin-top: 50px;
  width: 114px;
}

.touchupCancelBtn {
  background-color: #1a1e25;
  border-radius: 20px;
  margin-top: 50px;
  width: 114px;
}

.colorPanels {
  margin-top: 20px;
  text-align: center;
}

.colorPanels div {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

.colorPanels div:nth-of-type(-n + 5) {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: inline-block;
  margin: 4px;
}

.touchupContainer .colorContainer {
  margin-top: 25px;
  text-align: left;
}

.video-js.vjs-playing .vjs-poster {
  display: none;
}

.handa {
  fill: #fff;
}

.handb {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.active .handa {
  fill: yellow;
}

.background-compare path {
  fill: #fff;
}

.touchup-zoom input {
  margin-left: 15px;
}

.touchup-zoom .background-zoom-flip {
  padding-left: 10px;
}

.touchup-zoom .background-zoom-rotate {
  padding-left: 15px;
}

.touchupZoomOk {
  width: 114px;
  margin-top: -9px;
  background-color: #006dff;
  border-radius: 20px;
  width: 114px;
}

.touchupZoomOk:hover {
  background-image: linear-gradient(315deg, #8996d8 0%, #0349c8 74%);;
}

.touchupPanModeOk {
  line-height: 52px;
  text-align: center;
}

.touchupPanModeOk span {
  padding-right: 16px;
  font-size: 12px;
}

#touchupSliderPreview {
  position: fixed;
  top: 74px;
  left: 365px;
  width: 120px;
  height: 120px;
  background-color: #111316;
  border-radius: 5px;
  display: none;
  z-index: 5;
}

#touchupSliderPreview canvas {
  background-image: none !important;
}

.adBlocker {
  display: block;
  position: fixed;
  left: 345px;
  right: 50px;
  top: 88px;
  color: #fff;
  font-size: 18px;
  font-family: roboto, sans-serif;
}

.adBlocker img {
  width: 300px;
}

.adBlocker td {
  vertical-align: top;
}

.btn-trial {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: normal;
  padding: 5px 10px 5px 10px;
  background-color: #1976d2;
  display: block;
  white-space: nowrap;
}

.adBlocker div {
  padding-top: 40px;
}

.share-ad-holder {
  width: 300px;
  height: 550px;
  display: inline-block;
  margin: 15px;
}

.share-ad-holder iframe {
  margin-bottom: 33px;
}

.share-option-holder {
  width: 547px;
  position: absolute;
  right: 0px;
  top: 0;
  height: 550px;
}

.share-holder {
  padding: 20px;
}

.share-sources {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.share-cloud-holder {
  color: #fff;
  margin: 15px;
  background-color: #121318;
}

.cloud-title {
  margin-left: 20px;
  padding-top: 15px;
}

.cloud-title span {
  font-size: 16px;
}

.share-cloud-holder .share-sources {
  height: 55px;
  margin-top: 20px;
  margin-left: 20px;
}

.share-feedback-holder {
  margin-right: 15px;
  background-color: #121318;
  text-align: center;
  width: 250px;
  height: 200px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
}

.share-feedback-holder p {
  margin-top: 12px;
}

.pro-goodies {
  width: 250px;
  margin-top: 15px;
}

.cloud-title span {
  margin-right: 15px;
  font-size: 16px;
  vertical-align: super;
  margin-left: 15px;
}

.share-bottom-layer {
  margin: 15px;
}

.pro .pro-goodies {
  display: none;
}

.share-affiliate {
  margin: 15px;
  color: #fff;
  font-size: 18px;
}

.share-affiliate img {
  height: 30px;
  padding-right: 15px;
}

.share-affiliate span {
  vertical-align: super;
}

.share-newaffiliate {
  color: yellow;
  padding-right: 10px;
}

.share-affiliateprogram {
  font-weight: bold;
}

.share-affiliate a {
  vertical-align: super;
  margin-left: 10px;
}

.dropShadowSlider {
  position: relative;
  margin-bottom: 5px;
}

.dropShadowSlider .blue-slider {
  width: 160px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.dropShadowSlider span {
  margin-left: 10px;
}

.lds-css {
  margin: 40px auto;
  width: 60px;
}

@keyframes lds-square {

  0%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
  }

  20% {
    animation-timing-function: cubic-bezier(0, 0.1, 0.9, 1);
  }

  50% {
    animation-timing-function: cubic-bezier(0.1, 0, 1, 0.9);
  }

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes lds-square {

  0%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
  }

  20% {
    animation-timing-function: cubic-bezier(0, 0.1, 0.9, 1);
  }

  50% {
    animation-timing-function: cubic-bezier(0.1, 0, 1, 0.9);
  }

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.lds-square {
  position: relative;
}

.lds-square div {
  position: absolute;
  width: 60px;
  height: 60px;
  -webkit-animation: lds-square 1s infinite;
  animation: lds-square 1s infinite;
}

.lds-square div:nth-child(1) {
  top: 10px;
  left: 10px;
  background: #d74509;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.lds-square div:nth-child(2) {
  top: 70px;
  left: 10px;
  background: #ef6c00;
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-square div:nth-child(3) {
  top: 130px;
  left: 10px;
  background: #ffa726;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.lds-square div:nth-child(4) {
  top: 10px;
  left: 70px;
  background: #ef6c00;
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-square div:nth-child(5) {
  top: 70px;
  left: 70px;
  background: #ffa726;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.lds-square div:nth-child(6) {
  top: 130px;
  left: 70px;
  background: #00897b;
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.lds-square div:nth-child(7) {
  top: 10px;
  left: 130px;
  background: #ffa726;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.lds-square div:nth-child(8) {
  top: 70px;
  left: 130px;
  background: #00897b;
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.lds-square div:nth-child(9) {
  top: 130px;
  left: 130px;
  background: #00b0ff;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.lds-square {
  width: 60px !important;
  height: 60px !important;
  -webkit-transform: translate(-30px, -30px) scale(0.3) translate(30px, 30px);
  transform: translate(-30px, -30px) scale(0.3) translate(30px, 30px);
}

.square-category {
  display: inline-block;
  width: 70px;
  height: 45px;
  background-color: #1b1f25;
  margin: 15px 5px 5px 5px;
  padding-top: 10px;
  border-radius: 5px;
}

.straighten-container {
  text-align: left;
  margin-left: 15px;
  margin-top: 10px;
  color: #fff;
}

.straighten-container .blue-slider {
  width: 262px;
}

.straighten-container span {
  vertical-align: middle;
  padding-left: 10px;
}

.straighten-container svg {
  vertical-align: middle;
}

.auto-adjust-container {
  background-color: #1b1f25;
  border-radius: 6px;
  padding: 5px;
  margin-top: 15px;
  width: 130px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}

.auto-adjust-container span {
  vertical-align: middle;
  padding-left: 10px;
}

.auto-adjust-container svg {
  vertical-align: middle;
}

.cropCategory {
  color: #fff;
  height: 25px;
  padding: 20px;
}

.cropCategory.active {
  background-color: #343740;
}

.cropItem {
  background-color: #343740;
  border-color: #424851;
  border-style: solid;
  border-width: 1px;
  margin: auto;
}

.cropItemHolder {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.cropItemContainer {
  width: 210px;
  float: left;
  margin: 5px;
  color: #95959a;
  height: 40px;
  padding-left: 10px;
  padding-top: 5px;
  position: relative;
}

.cropItemContainer span {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}

.cropRightSide {
  float: left;
  height: 455px;
  overflow: auto;
  width: 706px;
  background-color: #272b33;
}

.cropTypeSelect {
  background-color: #1b1f25;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  position: relative;
}

.cropTypeSelect span {
  text-align: left;
  padding-top: 5px;
  padding-left: 20px;
}

.arrow-right {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #ffffff;
}

#lock-button {
  display: none;
  position: absolute;
  left: 250px;
  top: 100px;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

#lock-button svg {
  display: inline-block;
  vertical-align: middle;
}

#lock-button span {
  padding-left: 8px;
  vertical-align: middle;
}

.context-menu {
  display: none;
  position: absolute;
  z-index: 10;
  width: 180px;
  background-color: #1b1f25;
  border-radius: 7px;
  color: #fff;
}

.context-menu--active {
  display: block;
}

.context-menu__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu__item {
  display: block;
  margin-bottom: 4px;
}

.context-menu__item:last-child {
  margin-bottom: 0;
}

.context-menu__link {
  display: block;
  padding: 4px 12px;
  color: #fff;
  text-decoration: none;
}

.context-menu__link:hover {
  color: #fff;
  background-color: #0066aa;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0px 0px 9px 4px #0e98f4;
}

.accountPhoto {
  width: 80px;
  height: 80px;
  background-color: #1b1f25;
  border-radius: 50%;
  background-size: cover;
  margin-left: 20px;
  margin-top: 10px;
}

.accountLabel {
  padding-top: 20px;
  font-size: 24px;
  color: #aaa;
}

.accountValue {
  font-size: 20px;
  color: #aaa;
  margin-left: 20px;
  margin-top: 10px;
}

.account-container {
  padding-left: 20px;
}

.accountDelete button {
  height: 42px;
  width: 200px;
  background-color: red;
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  border: none;
}

.accountDelete {
  text-align: center;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

#accountEmail {
  margin-top: 10px;
}

.startPage {
  background-color: #16191e;
  height: 100%;
  overflow-x: hidden;
}

.startPage .navbar {
  background-color: #16191e;
}

.navbarTitle {
  color: #fff;
  font-size: 26px;
  text-align: center;
  display: block;
  margin-top: 16px;
  padding-left: 250px;
}

.startPage .logo-text {
  height: 30px !important;
  max-width: initial;
  padding-top: 15px;
}

.startPage .logo-div {
  height: 86px !important;
  text-align: center;
}

.startPage .colorBar div {
  height: 7px;
}

.startPage .leftmenu {
  overflow: hidden;
  padding: 25px;
  margin-top: 76px;
  color: #fff;
  font-size: 16px;
}

.startMenuItem.active {
  background-color: #2d3644;
  margin-right: 10px;
}

.startMenuItem.active.folder:hover {
  background-image: url("./images/editor/edit_pencil_icon.svg");
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 14px;
}

.startMenuItem {
  margin-bottom: 16px;
  border-radius: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
}

.startMenuItem:hover {
  background-color: #2d3644;
  margin-right: 10px;
}

.startMenuItem a {
  color: #fff !important;
  display: flex;
  align-items: center;
}

.startMenuItem .foldername {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 84px;
}

.startMenuItem a path {
  stroke: #fff !important;
  fill: #fff !important;
}

.startMenuItem svg {
  padding-right: 15px;
  height: 20px;
  vertical-align: baseline;
}

.startMenuItem.active path {
  stroke: #ffffff;
  fill: #ffffff;
}

.startMenuItem path {
  stroke: #545f71;
  fill: #545f71;
}

.inplaceeditcontrols {
  padding-top: 5px;
  display: flex !important;
  margin-left: 10px !important;
  justify-content: space-between;
  align-items: center;
}

.inplaceeditcontrols svg {
  height: 12px;
  padding-right: 12px;
}

.inplaceeditcontrols path {
  stroke: #ffffff !important;
  fill: #ffffff !important;
}

.inplaceeditcontrols .close:hover path {
  stroke: #ff0000 !important;
  fill: #ff0000 !important;
}

.inplaceeditcontrols .check:hover path {
  stroke: #00ff00 !important;
  fill: #00ff00 !important;
}

.startMenuItem.active svg {
  padding-left: 20px;
  height: 16px;
}

.startPage .leftmenu a svg {
  height: 16px;
  padding-right: 20px;
  padding-left: 20px;
}

.startPage .leftmenu a path {
  stroke: #ffffff;
  fill: #ffffff;
}

.startPage .leftmenu .clickable:hover {
  color: #ffffff !important;
}

.startPage .leftmenu .clickable:hover .st0 {
  stroke: #ffffff;
  fill: #ffffff;
}

.startPage .leftmenu a {
  color: #ffffff;
}

.startPage .uploaded {
  top: -22px !important;
  min-height: calc(100% + 22px) !important;
  height: calc(100% + 22px) !important;
  left: 250px;
  bottom: 0;
  right: 0;
  float: initial;
  width: initial;
  position: absolute;
  color: #fff;
  background-color: #16191e;
}

.startPage .container {
  left: 238px;
  top: 101px;
  bottom: 0;
  right: 0;
  float: initial;
  width: initial;
  position: absolute;
  color: #fff;
  background-color: #16191e;
  min-height: calc(100% - 101px) !important;
  height: calc(100% - 101px) !important;
  overflow-x: hidden;
}

.nonpro .container {
  right: 172px !important;
}

.quick-control-title {
  font-weight: bold;
  font-size: 36px;
  color: #fff;
  padding-bottom: 80px;
}

.quickControls {
  background-image: url("//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_background.f51f5035.jpg");
  margin-right: 25px;
  padding-top: 36px;
  text-align: center;
  border-radius: 8px;
  background-size: inherit;
}

.quick-icon {
  background: #000;
  width: 26px;
  height: 26px;
  padding: 12px;
  border-radius: 26px;
  margin: 5px;
}

.quickControl {
  display: inline-block;
  margin-top: 6px;
  margin: 0px 6px 0px;
  width: 65px;
}

.quickControl span {
  display: block;
  color: #fff;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 60px;
  text-align: center;
  height: 50px;
}

.quickControl .edit_photo {
  height: 29px;
  width: 31px;
}

.quickControl svg {
  height: 26px;
  width: 26px;
}

.quickControl img {
  height: 40px;
  padding-top: 5px;
}

.roundIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 12px;
}

.roundRed {
  background-color: #fe2504;
}

.roundOrange {
  background-color: #ff8901;
}

.roundYellow {
  background-color: #ffb701;
}

.roundLightGreen {
  background-color: #79b343;
}

.roundGreen {
  background-color: #08766b;
}

.roundLightBlue {
  background-color: #00b7fd;
}

.roundBlue {
  background-color: #1c77d2;
}

.roundPurple {
  background-color: #7518e3;
}

.featureTitle {
  font-size: 18px;
}

.featureTitles {
  padding-bottom: 20px;
}

.seeAll {
  float: right;
}

.featureImages {
  height: 225px;
  white-space: nowrap;
  overflow: hidden;
}

.app-store-container {
  padding: 20px;
}

.app-store img {
  height: 48px;
  width: 128px;
}

.featureGallery {
  padding-left: 25px;
  padding-right: 20px;
}

.galleryImage {
  display: inline-block;
}

.galleryImage img {
  height: 225px;
  padding-right: 3px;
}

.cutoutGallery {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding: 10px;
}

.cutoutGallery .galleryImage img {
  height: 150px;
  padding-right: 3px;
}

.exportGallery {
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding: 10px;
}

.exportGallery .galleryImage img {
  height: 120px !important;
  padding-right: 3px;
}

.galleries {
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 25px;
}

.galleryTitle {
  padding-bottom: 15px;
  padding-top: 25px;
}

.galleryTitle span {
  font-size: 18px;
}

.galleryTitle a {
  float: right;
  font-size: 14px;
  color: #fff;
}

.galleries #ReactGridGallery {
  display: flex;
}

.ReactGridGallery_custom-overlay {
  z-index: 2;
  pointer-events: auto !important;
}

.thumbOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.thumbOverlayTop {
  float: right;
  padding-top: 10px;
  padding-left: 12px;
}

.thumbOverlayTop svg {
  width: 22px;
  height: 20px;
  padding-right: 15px;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.thumbOverlayTop div {
  display: inline-block;
  margin-bottom: 8px;
}

.thumbOverlayTop .a:hover,
.thumbOverlayEdit .a:hover {
  fill: #ffffff;
}

.thumbOverlayEdit {
  height: 100%;
  width: 100%;
}

.thumbOverlayEdit button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  color: #fff;
  background-color: rgba(13, 15, 17, 0.65);
  border-radius: 3px;
  font-size: 20px;
  border: 0;
  padding: 6px 10px 6px 10px;
}

.thumbOverlayEdit button svg {
  padding-left: 10px;
}

.addFolderContainer .addFolderInput {
  width: 93%;
  margin-left: 2px;
}

.addFolderInput {
  border: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding: 0.25em 0.5em 0.3125em;
  color: #fff;
  border-radius: 8px;
  background: transparent;
  margin-left: 10px;
  width: 108px;
}

.addFolderButtons {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  margin-right: 10px;
}

.folder__error {
  color: red;
  text-align: left;
  font-size: 12px;
  display: block;
  margin-top: 3px;
  margin-left: 4px;
  text-align: center;
}

.addFolderButtons button {
  background-color: #006dff;
  border-radius: 2px;
  width: 90px;
  margin-top: -9px;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.addFolderButtons .cancel-btn {
  background-color: #c31212;
}

.addFolderContainer {
  padding-top: 10px;
}

.projectTitleContainer {
  position: absolute;
  top: 13px;
  left: 0;
}

.projectTitleContainer span {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.projectTitleContainer .addFolderInput {
  margin-left: 0 !important;
  margin-top: -5px;
}

.projectTitle {
  font-size: 22px !important;
}

.nospace svg {
  vertical-align: top;
  padding-right: 10px;
  padding-left: 15px;
}

.nospace svg path {
  fill: #ffff00;
}

.nospace {
  font-size: 14px !important;
  vertical-align: middle;
}

.nospacea {
  font-size: 14px !important;
  color: #1976d2;
}

.statusMessage {
  position: absolute;
  left: 60px;
  width: 200px;
  color: #aaa;
}

.abcRioButtonIconImage svg {
  width: 33px;
  height: 36px;
}

.abcRioButtonIcon {
  padding-top: 0 !important;
  padding-left: 14px !important;
}

.abcRioButtonLightBlue {
  height: 54px !important;
  width: 350px !important;
  margin-bottom: 13px;
  margin-left: 50px;
  border-radius: 8px;
}

.moveToPopup {
  position: absolute;
  top: 30px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  width: 140px;
  z-index: 1;
  padding: 10px;
  border-radius: 8px;
  height: 140px;
  border: solid 1px #66686b;
  overflow: auto;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.moveToPopup span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 70px;
}

.moveToFolder {
  padding: 5px;
  display: block !important;
}

.moveToFolder svg {
  height: 16px;
}

.moveToFolder span {
  display: inline-block;
}

.textedit-container {
  display: flex !important;
  justify-content: space-between;
}

.texteditInput {
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 16px;
  padding: 0.25em 0.5em 0.3125em;
  color: #fff;
  border-radius: 8px;
  background: transparent;
}

.proContainerContents {
  z-index: 100;
  position: relative;
}

.startPage .statusMessage {
  position: absolute;
  top: 20px;
  left: 300px;
  color: #ffffff;
}

.folder-delete {
  display: flex;
  border-radius: 8px;
  padding: 10px 20px;
  background: #252a30;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.folder-delete span {
  margin-left: 15px;
  vertical-align: bottom;
}

.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: "PT Sans", sans-serif;
  color: #424242;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;
}

.confirm-dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 440px;
  height: 152px;
  overflow: hidden;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 2;
  border-radius: 8px;
  border-style: solid;
  border-color: #444;
  border-width: 1px;
  animation: modal-elastic-flip-animation 0.35s ease;
}

.confirm-dialog-container span {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 20px 32px 20px;
}

.confirm-dialog-container button.confirmAcceptBtn {
  float: right;
  bottom: 10px;
  background-color: #d21919;
}

.confirm-dialog-container button.confirmCancelBtn {
  float: right;
  bottom: 10px;
  background-color: #006dff;
}

.welcomeContainer {
  padding-top: 40px;
  font-size: 18px;
  text-align: center;
}

.welcomeContainer img {
  padding-bottom: 30px;
}

.colorCodeManual {
  background-color: 0;
  font-size: 18px;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 30px;
  text-align: right;
  margin-right: 30px;
}

.colorCodeManual input {
  height: 30px;
  width: 90px;
  margin-right: 20px;
}

.alreadySigned {
  margin-top: 20px;
  font-size: 14px;
}

.lineOr {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lineOr hr {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
  border-color: #838f93;
}

.lineOr span {
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.forgotWait {
  width: 300px;
  border: #ffa726 solid 2px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 40px;
  color: #ffa726;
  margin-top: 15px;
  font-size: 14px;
}

.forgotWait b {
  font-size: 13px;
  font-weight: bolder;
  font-family: "PT Sans", sans-serif;
}

.cancel {
  position: absolute;
  margin: 10px;
  right: 5px;
}

.forgotTitle {
  font-size: 20px;
  margin-top: 40px;
  padding-bottom: 15px;
}

.facebook {
  color: #4e66bc;
  font-size: 13px;
  font-weight: bolder;
  font-family: arial;
}

.google {
  color: #ffffff;
  font-size: 13px;
  font-weight: bolder;
  font-family: arial;
}

.twitter {
  color: #77a7e9;
  font-size: 13px;
  font-weight: bolder;
  font-family: arial;
}

.recentEmail {
  font-size: 14px;
}

.validateContainer {
  position: relative;
}

.validateContainer input {}

.validateMessage {
  position: absolute;
  left: 324px;
  user-select: none;
  width: 240px;
  text-align: left;
}

.forgot-validate {
  left: 392px;
}

.login-main-register .validateMessage {
  left: 335px;
}

.validateMessage img {
  display: inline;
  vertical-align: top;
  padding-top: 5px;
  height: 15px;
}

.validateMessage span {
  background-color: #e03949;
  display: inline-block;
  border-radius: 2px;
  vertical-align: middle;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 5px;
  max-width: 200px;
  margin-top: -1px;
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
  margin-right: 8px;
  margin-left: 8px;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 274px;
  border-radius: 2px;
  margin-left: 4px;
  padding-top: 12px;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
  margin-top: 10px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  color: #000;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.colorHistory {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 185px;
  font-size: 14px;
}

.colorHistory span {
  display: inline-block;
  margin-right: 20px;
  position: initial !important;
}

.colorHistoryTile {
  border-radius: 3px;
  cursor: pointer;
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 7px;
}

.searchTabs {
  position: absolute;
  top: 30px;
  left: 200px;
  font-size: 14px;
}

.searchTabs span {
  color: #fff;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-right: 40px;
}

.searchTabs span.active {
  border-bottom: #00b0ff 3px solid;
}

.doubleArrow {
  position: absolute;
  top: 128px;
  height: 30px;
  left: 360px;
  z-index: 10;
  -webkit-animation: bounceLeft 2s infinite;
  animation: bounceLeft 2s infinite;
}

.doubleArrow path {
  fill: #ffff00;
}

@-webkit-keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  60% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}

@-moz-keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(30px);
  }

  60% {
    transform: translateX(15px);
  }
}

@keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  60% {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}

.collageText {
  margin-top: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.sticker-scroll-items {
  overflow: hidden;
}

.sticker-scroll-items img {
  max-width: 45px;
  max-height: 60px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.sticker-scroll-items img:hover {
  scale: 1.1;
}

#clear {
  cursor: pointer;
  margin: auto -23px;
}

#clear svg {
  height: 15px;
  vertical-align: middle;
}

.ReactGridGallery_tile {
  border-radius: 4px !important;
}

.ReactGridGallery_tile-viewport {
  border-radius: 4px !important;
}

.ReactGridGallery_tile-bottom-bar {
  display: none;
}

.graphicsTabs,
.photosTabs {
  color: #fff;
  background-color: #3b3e48;
}

.graphicsTabs div {
  width: 50%;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.photosTabs div {
  width: 33.33333%;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.photos-nav {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
}

.photos-nav span {
  width: 152px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.photos-nav img {
  height: 20px;
}

.graphicsTabs div.selected,
.photosTabs div.selected {
  border-bottom: #00b0ff 3px solid;
}

.graphicContainer {
  overflow-y: auto;
  height: calc(100% - 46px);
}

.productRow {
  margin-left: 50px;
}

.productRow span {
  display: inline-block;
  margin-right: 30px;
}

.productRow input {
  width: 50px;
  margin-right: 30px;
}

.productDescription {
  width: 400px;
}

.productTotalRow {
  margin-left: 531px;
}

.printContainer {
  margin-top: 70px !important;
  text-align: center;
}

.printThumb {
  max-width: 300px;
  max-height: 300px;
}

.printInfo {
  text-align: left;
  vertical-align: top;
}

.printInfo input {
  margin-bottom: 20px;
  margin-right: 20px;
}

.printInfo .email {
  width: 320px;
}

.printInfo .phone {
  width: 320px;
}

.printProducts {
  margin-top: 25px;
}

.printStores {
  text-align: center;
  margin-top: 25px;
}

.printStore {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.printStore div {
  text-align: left;
}

.printStores .a {
  fill: #000000;
}

.storeSearch {
  background-color: #1976d2;
  border-radius: 2px;
  margin-left: 30px;
  width: 114px;
}

.walgreens_logo {
  height: 22px;
}

.free {
  height: 17px;
  color: #ffffff;
  border-radius: 9px;
  width: 37px;
  background-color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Arial";
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 8px;
  letter-spacing: 1px;
}

.pro-crown {
  height: 17px;
  width: 37px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.pro-crown img {
  border-radius: 1px !important;
}

.ReactGridGallery_tile-description {
  background-color: initial !important;
  height: auto !important;
}

.notfound-container svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}

.message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: #fff;
  font-family: Roboto;
  font-weight: 300;
}

.message-box h1 {
  font-size: 60px;
  line-height: 46px;
  margin-bottom: 40px;
}

.buttons-con .action-link-wrap {
  margin-top: 40px;
}

.buttons-con .action-link-wrap a {
  background: #68c950;
  padding: 8px 25px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}

.buttons-con .action-link-wrap a:hover {
  background: #5a5c6c;
  color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}

#Polygon-2 {
  animation-delay: 0.2s;
}

#Polygon-3 {
  animation-delay: 0.4s;
}

#Polygon-4 {
  animation-delay: 0.6s;
}

#Polygon-5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 450px) {
  .notfound-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }

  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #006dff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #006dff;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.background-image {
  margin: 30px auto 0 auto;
  width: 175px;
  border-radius: 24px;
  padding: 10px 17px;
  background: #006dff;
  display: flex;
  align-items: center;
}

.delete-background-image {
  margin: 30px auto 0 auto;
  width: 190px;
  border-radius: 24px;
  padding: 10px 17px;
  background: #ff0000;
  display: flex;
  align-items: center;
}

.background-image span,
.delete-background-image span {
  font-size: 14px;
  margin-left: 10px;
  color: #fff;
}

.clickable-button {
  border-radius: 20px;
  padding: 6px 20px;
  background: #1b1f25;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable-button:hover {
  color: #006dff;
}

.clickable-button:hover .st0 {
  fill: #006dff;
}

.semi-clickable-button {
  border-radius: 20px;
  padding: 8px 15px;
  background: #1b1f25;
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.auto-adjust-btn {
  cursor: pointer;
  margin: auto;
  border-radius: 20px;
  padding: 8px 16px;
  background: #006dff;
  font-size: 16px;
  width: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.semi-clickable-button:hover {
  color: #006dff;
}

.semi-clickable-button:hover .st0 {
  fill: #006dff;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-range input[type="range"] {
  margin-top: 20px;
  -webkit-appearance: none;
  width: 100%;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#006dff, #006dff);
  background-repeat: no-repeat;
  height: 2px;
  cursor: pointer;
  background-color: #fff;
}

.range-slider {
  margin-bottom: 20px;
}

.input-range-slider:focus {
  outline: none;
}

.input-range-slider:active {
  outline: none;
}

.input-range-slider::-webkit-slider-thumb {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #252a32;
  cursor: pointer;
  border: 2px solid #fff;
  outline: 5px solid #252a32;
  -webkit-appearance: none;
  margin-top: -3px;
}

.input-range input[type="range"]::-webkit-slider-thumb {
  height: 16.5px;
  width: 16.5px;
  border-radius: 50%;
  background: #252a32;
  cursor: pointer;
  border: 2px solid #fff;
  outline: 5px solid #252a32;
  -webkit-appearance: none;
  margin-top: -2px;
}

.input-range {
  margin-bottom: 35px;
}

.effectContainer .title {
  font-size: 12px;
  color: #fff;
  text-align: left;
}

.effectContainer .reset {
  font-size: 12px;
  color: #006dff;
}

.divider {
  width: 100%;
  border-bottom: 2px solid #4b4f56;
}

.effect-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.round-rect {
  padding: 14px;
  background: #1b1f25;
  border-radius: 5px;
}

.round-rect-span {
  font-size: 14px;
  color: #fff;
}

.color-box {
  margin-left: 15px;
  margin-top: 2px;
  width: 30px;
  height: 25px;
  background: #fff;
  border-radius: 2px;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.d-flex-start {
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.ai-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.seel-all {
  font-size: 12px;
  color: #006dff;
}

.react-multiple-carousel__arrow {
  min-width: 23px !important;
  min-height: 23px !important;
  top: 34px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 16px !important;
  font-weight: 900;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.scroll-container {
  overflow-y: auto;
  height: calc(100% - 46px);
}

input[type="range"] {
  position: relative;
}

.twitter-error-modal {
  padding: 20px;
  width: 650px;
  z-index: 1003;
  height: 220px;
  position: absolute;
  text-align: left;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  border-radius: 5px;
}

.twitter-error-modal .modal-body {
  font-size: 16px;
  line-height: 36px;
}

.twitter-error-modal .modal-footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.twitter-error-modal .modal-footer .confirm-btn {
  background: #006dff;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.twitter-error-modal .modal-footer .confirm-btn:hover {
  background: #006df0;
}

.welcome-pro-modal {
  width: 650px;
  z-index: 1003;
  height: 484px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  border-radius: 5px;
}

.welcome-pro-modal .modal-header {
  background-image: url("https://cdn.pizap.com/pizapfiles/piZap-PRO-details-header-2022-iPhone.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 282px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.welcome-pro-modal .modal-body {
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
}

.welcome-pro-modal .modal-footer {
  float: right;
  padding: 20px;
}

.welcome-pro-modal .modal-footer .confirm-btn {
  background: #006dff;
  width: 160px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.ai-error-modal {
  padding: 56px 32px 32px 32px;
  width: 624px;
  z-index: 1003;
  height: 440px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  border-radius: 5px;
}

.ai-error-modal .modal-body {
  font-size: 18px;
  line-height: 26px;
}

.ai-error-modal .modal-footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.ai-error-modal .modal-footer .confirm-btn {
  background: #006dff;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.usage-limit-modal {
  height: 312px !important;
}

.sticker-error-modal {
  padding: 20px;
  width: 650px;
  z-index: 1003;
  height: 124px;
  position: absolute;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  background-color: #1c1f26;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  border-radius: 5px;
  animation: modal-elastic-flip-animation 0.35s ease;
  border: solid 1px #66686b;
}

.sticker-error-modal .modal-body {
  font-size: 16px;
  line-height: 36px;
}

.sticker-error-modal .modal-footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.sticker-error-modal .modal-footer .delete-btn {
  background: #e32c2c;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.sticker-error-modal .modal-footer .delete-btn:hover {
  background: #f32c2c;
}

.sticker-error-modal .modal-footer .confirm-btn {
  background: #006dff;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.sticker-error-modal .modal-footer .confirm-btn:hover {
  background: #006df0;
}

.add-bg {
  margin-top: 17px;
}

.rc-slider {
  margin-top: 12px;
  padding: 0 !important;
}

.rc-slider-track {
  position: absolute;
  height: 2px !important;
  background-color: #006dff !important;
  border-radius: 2px !important;
  border-color: white;
  border-style: solid;
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 2px !important;
  background-color: #686666 !important;
  border-radius: 6px;
}

.rc-slider-handle {
  position: absolute;
  height: 16.5px !important;
  width: 16.5px !important;
  border-radius: 50%;
  background: #252a32 !important;
  cursor: pointer !important;
  border: 2px solid #fff !important;
  outline: 5px solid #252a32;
  -webkit-appearance: none;
  margin-top: -7px !important;
  touch-action: pan-x;
  opacity: 1 !important;
}

.rc-slider-handle:focus {
  border: 2px solid #fff !important;
  outline: 5px solid #252a32 !important;
}

.temperature-slider {
  background: linear-gradient(270deg,
      #ffdc00 0,
      #9d9d9d,
      #02beff 100%) !important;
  height: 2px !important;
  margin-bottom: 10px;
}

.temperature-slider .rc-slider-track {
  height: 0 !important;
}

.temperature-slider .rc-slider-rail {
  height: 0 !important;
}

.tint-slider {
  background: -webkit-linear-gradient(left,
      #ff0000 0%,
      #ffff00 17%,
      #00ff00 33%,
      #00ffff 50%,
      #0000ff 67%,
      #ff00ff 83%,
      #ff0000 100%);
  background: linear-gradient(to left,
      #ff0000 0%,
      #ffff00 17%,
      #00ff00 33%,
      #00ffff 50%,
      #0000ff 67%,
      #ff00ff 83%,
      #ff0000 100%);
  height: 2px;
  margin-bottom: 10px;
}

.tint-slider .rc-slider-track {
  height: 0 !important;
}

.tint-slider .rc-slider-rail {
  height: 0 !important;
}

.blue-slider {
  background: -webkit-linear-gradient(270deg, #006dff 0, #ff0);
  background: linear-gradient(270deg, #006dff 0, #ff0);
  height: 2px;
  margin-bottom: 10px;
}

.blue-slider .rc-slider-track {
  height: 0 !important;
}

.blue-slider .rc-slider-rail {
  height: 0 !important;
}

.green-slider {
  background: -webkit-linear-gradient(left, #00ff00 0%, purple 100%);
  background: linear-gradient(to left, #00ff00 0%, purple 100%);
  height: 2px;
  margin-bottom: 10px;
}

.green-slider .rc-slider-track {
  height: 0 !important;
}

.green-slider .rc-slider-rail {
  height: 0 !important;
}

.red-slider {
  background: -webkit-linear-gradient(270deg, red 0, #006dff);
  background: linear-gradient(270deg, red 0, #006dff);
  height: 2px;
  margin-bottom: 10px;
}

.red-slider .rc-slider-track {
  height: 0 !important;
}

.red-slider .rc-slider-rail {
  height: 0 !important;
}

.rc-slider-handle-dragging {
  box-shadow: none !important;
}

.layer-context-menu {
  display: none;
  position: absolute;
  z-index: 10;
  width: 170px;
  left: 35px;
}

.context-title {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}

.disabled-menu {
  pointer-events: none;
  opacity: 0.4;
}

.input-number-group {
  display: flex;
  position: relative;
}

.input-number {
  width: 40px;
  text-align: center;
  outline: none;
}

.input-number,
.input-number-decrement,
.input-number-increment {
  height: 36px;
}

.input-number-increment:hover {
  color: #006dff;
}

.input-number span:hover {
  color: #006dff;
}

.input-number-decrement:hover {
  color: #006dff;
}

.input-number {
  line-height: 38px;
  border-radius: 0px !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  background-color: #1a1e25 !important;
  color: #fff !important;
  font-size: 16px !important;
}

.input-number:focus {
  box-shadow: none !important;
}

.input-number-decrement,
.input-number-increment {
  display: inline-block;
  width: 28px;
  line-height: 34px;
  background: #1a1e25;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 30px;
}

.input-number-decrement:active,
.input-number-increment:active {
  background: #ddd;
}

.input-number-decrement {
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.font-change-modal {
  position: absolute;
  background: #1a1e25;
  top: 294px;
  left: 128px;
  border-radius: 8px;
  width: 136px;
  height: 496px;
  overflow-y: auto;
  z-index: 10;
}

.font-select-item {
  text-align: left;
  padding: 12px;
}

.font-select-item:hover {
  background-color: #006dff !important;
  cursor: pointer;
}

.font-select-item span {
  font-size: 15px;
}

.font-check {
  margin-left: 50px;
}

.font-control-group {
  position: relative;
}

.font-click-modal {
  position: absolute;
  background-color: #1a1e25 !important;
  width: 193px;
  padding: 25px;
  border-radius: 8px;
  top: 36px;
  left: 0px;
  z-index: 10;
  border-width: 1px;
  border-style: solid;
  border-color: #808080;
  filter: drop-shadow(0px 2px 4px black);
}

.font-click-modal.align {
  left: -56px !important;
  width: 110px !important;
  padding: 8px !important;
}

.font-click-modal.spacing {
  left: -204px !important;
  top: 42px !important;
}

.font-click-modal.align .active {
  background-color: #006dff !important;
  color: #fff !important;
}

.image-border-radius {
  border-radius: 5px;
}

.text-control-title {
  font-size: 16px;
  color: #fff;
  margin-left: 20px;
}

.text-controls-slider {
  width: 200px;
}

.text-scroll {
  height: calc(100% - 46px);
  overflow-y: auto;
}

.transparency-slider {
  width: 85px !important;
  margin-left: 10px !important;
  margin-top: 20px !important;
}

.image-text-item {
  width: 50%;
}

.image-text-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 13px;
}

.st0 {
  fill: #ffffff;
}

.st0:hover {
  fill: #006dff;
}

.context-menu .prop-group span {
  margin-left: 20px;
  font-size: 16px;
}

.clickable:hover {
  color: #006dff;
}

.clickable:hover .st0 {
  fill: #006dff;
}

.slider-color-left {
  width: 78%;
  margin-top: 7px;
}

.sub-menu {
  background-color: #2f353f;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cutout-title {
  background-color: #131518;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cutout-style-container {
  display: flex;
  align-items: flex-start;
}

.btn-radio {
  cursor: pointer;
  display: inline-block;
  float: left;
  -webkit-user-select: none;
  user-select: none;
}

.btn-radio:not(:first-child) {
  margin-left: 20px;
}

@media screen and (max-width: 480px) {
  .btn-radio {
    display: block;
    float: none;
  }

  .btn-radio:not(:first-child) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.btn-radio svg {
  fill: none;
  vertical-align: middle;
}

.btn-radio svg circle {
  stroke-width: 2;
  stroke: #c8ccd4;
}

.btn-radio svg path {
  stroke: #008fff;
}

.btn-radio svg path.inner {
  stroke-width: 6;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
}

.btn-radio svg path.outer {
  stroke-width: 2;
  stroke-dasharray: 57;
  stroke-dashoffset: 57;
}

.btn-radio input {
  display: none;
}

.btn-radio input:checked+svg path {
  transition: all 0.4s ease;
}

.btn-radio input:checked+svg path.inner {
  stroke-dashoffset: 38;
  transition-delay: 0.3s;
}

.btn-radio input:checked+svg path.outer {
  stroke-dashoffset: 0;
}

.picker-title {
  background-color: #131518;
  color: white;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.filters-btn {
  margin-bottom: 10px;
}

.filters-btn .autofix {
  width: 100px;
  border-radius: 24px;
  padding: 10px 17px;
  background: #006dff;
  margin: auto;
  color: #fff;
}

.filter-label {
  color: #fff;
  font-size: 14px;
  margin-left: 30px;
}

.filters-item img {
  cursor: pointer;
}

.filters-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  background-color: #1b1f25;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.filters-item:hover {
  background-color: #006dff;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.qualityboxes {
  margin-top: 40px;
}

.qualitybox .btn-radio:not(:first-child) {
  margin-left: 0px;
}

.qualitybox .btn-radio {
  cursor: pointer;
  display: inline-block;
  float: inherit;
  user-select: none;
  margin-top: 10px;
}

.custom-container {
  margin-top: 30px;
  text-align: center;
}

.graphicContainer ul {
  columns: 100px;
  break-inside: avoid;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.graphicContainer ul li {
  list-style-type: none;
  position: relative;
}

figure {
  position: relative;
}

figure .user-platform {
  display: none;
  position: absolute;
  z-index: 1;
  color: white;
  bottom: 2px;
  right: 10px;
  white-space: nowrap;
  text-align: right;
}

figure .user-platform:hover {
  display: none;
  position: absolute;
  z-index: 1;
  color: #0060ff;
  bottom: 2px;
  right: 10px;
}

figure:hover .user-platform {
  display: block;
}

#designpicker-album-files img:hover {
  scale: 1.1;
}

#menu-container.large .photopicker-list-item img:hover {
  opacity: 0.6;
}

.graphicContainer figure img {
  width: 124px;
  display: block;
  margin-top: 0px;
  object-fit: cover;
  height: 100%;
}

.graphicContainer figure img:hover {
  scale: 1.05;
}

.text-left {
  text-align: left;
  background: none;
  margin-top: 6px;
}

.input-group {
  line-height: 45px;
  font-size: 16px;
  text-align: center;
  margin-top: -36px;
}

.input-group input {
  padding-left: 25px !important;
  width: 250px;
  padding: 0.3em 0.7em;
}

.input-group .search-icon {
  position: absolute;
  top: 15px;
  left: 424px;
}

.input-group .clear svg path {
  fill: #8d9296 !important;
}

.input-group .search-icon svg path {
  fill: #aaa !important;
}

.input-group .searchIcon {
  width: 17px;
}

.stock-image-category {
  color: #fff;
}

.stock-category {
  font-size: 12px;
  white-space: nowrap;
  padding: 3px 12px;
  border-radius: 12px;
  margin-left: 3px;
  margin-right: 3px;
  color: #fff;
  background-color: #2b3038;
}

.stock-category.active {
  color: #006dff;
}

.image-src-tabs {
  padding-top: 12px;
  gap: 32px;
  display: flex;
  justify-content: center;
}

.quickControls .image-src-tabs .tab-item {
  margin-bottom: 16px;
}

.image-src-tabs .tab-item {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.image-src-tabs .tab-item span {
  color: #fff;
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
}

.image-src-tabs .tab-item.selected {
  border-bottom: #00b0ff 3px solid;
}

#photopicker-album-files ul {
  -webkit-columns: 124px;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-left: 28px;
  margin-right: 28px;
  margin-top: 0px;
  margin-bottom: 10px;
}

#photopicker-album-files ul li {
  width: 107%;
  list-style-type: none;
  position: relative;
}

#photopicker-album-files ul li:hover .user-platform {
  display: block;
}

#photopicker-album-files ul li .user-platform {
  display: none;
  position: absolute;
  z-index: 1;
  color: white;
  font-size: 12px;
  bottom: 2px;
  right: 10px;
  white-space: nowrap;
  text-align: right;
}

#photopicker-album-files ul li .user-platform:hover {
  display: block;
  position: absolute;
  z-index: 1;
  color: #0060ff;
  bottom: 2px;
  right: 10px;
}

#designpicker-album-files ul li,
#collagepicker-album-files ul li {
  list-style-type: none;
  position: relative;
}

#photopicker-album-files ul li img,
#designpicker-album-files ul li img,
#collagepicker-album-files ul li img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  margin-top: 0px;
  margin-bottom: 7px;
  border-radius: 8px;
}

.stock-image-dialog-box {
  padding-bottom: 30px;
}

.share-item {
  margin-top: 10px;
  background-color: #121318;
  width: 100px;
  height: 90px;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.share-item:hover {
  filter: sepia(100%);
  transition: .3s ease-in-out;
}

.share-title {
  color: #fff;
  font-size: 16px;
}

.referral-link-input {
  margin-top: 10px;
  position: relative;
}

.referal-url-input {
  padding: 10px 40px 10px 10px;
  text-align: left;
  width: 100%;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  margin-left: 0px !important;
}

.referral-link-input button {
  position: absolute;
  background-color: #006dff;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #fff;
  right: 3px;
  top: 4px;
  padding: 7px;
}

.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 3px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.copy-link-loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  position: absolute;
  scale: 0.4;
  right: 0;
  top: 0;
}

.uploads-dialog {
  height: 97% !important;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.no-images {
  position: absolute;
  left: 42%;
  top: 50%;
  color: #fff;
}

.no-pizap-images {
  position: absolute;
  left: 39%;
  top: 50%;
  color: #fff;
}

.no-cutouts {
  position: absolute;
  left: 34%;
  top: 50%;
  color: #fff;
  text-align: center;
}

.referral-link-input span {
  position: absolute;
  background-color: #1c1f26;
  border: none;
  border-radius: 8px;
  color: #fff;
  right: 3px;
  top: 4px;
  padding: 7px;
}

b {
  font-weight: 700;
}

.cursor-image {
  cursor: var(--custom-cursor) !important;
}

#animated-element {
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
}

.animate {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  /* ease-out transition */
  opacity: 0;
  animation: vibrate 0.3s infinite;
}

@keyframes vibrate {
  0% {
    transform: translateX(-300px);
  }

  25% {
    transform: translateX(-150px);
  }

  75% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(0px);
  }
}

.title-text {
  font-size: 19px;
  color: #feffc9;
  float: left;
}

.title-suggestions {
  font-size: 14px;
  float: left;
}

.suggestions-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ai-create-btn {
  width: 80%;
  border-radius: 24px;
  padding: 12px 17px;
  background: #006dff;
  color: #fff;
  border: none;
  margin: auto;
  cursor: pointer;
}

.ai-create-btn:hover {
  background: #0060ff;
}

.trial-btn {
  width: 100%;
  border-radius: 24px;
  padding: 12px 17px;
  background: #006dff;
  color: #fff;
  border: none;
  margin: auto;
  cursor: pointer;
}

.trial-btn:hover {
  background: #0060ff;
}

.red-highlight {
  background-color: #ff0000;
  padding: 3px 6px;
  border-radius: 24px;
  font-size: 11px !important;
}

.payment-plan {
  margin-top: -20px;
}

.payment-plan .plan {
  font-size: 18px;
  font-weight: bold;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.payment-value {
  font-size: 14px !important;
}

.payment-value span {
  font-size: 14px !important;
  color: gray;
}

.option-text {
  color: gray;
  font-size: 15px !important;
}

.option-text-trial {
  font-size: 15px;
}

.option-text-trial span span {
  color: #00ff00;
  font-weight: bold;
}

.ai-image-slider {
  margin-top: -60px !important;
  width: 45%;
  margin: auto;
}

.ai-showcase-img {
  border-radius: 5px;
}

.ai-image-slider .react-multiple-carousel__arrow {
  min-width: 23px !important;
  min-height: 23px !important;
  top: 80px !important;
}

.ai-prompt-text {
  background-color: #fff !important;
  padding: 10px !important;
  color: #000 !important;
  height: 65px !important;
  resize: none !important;
}

.ai-image-slider .react-multiple-carousel__arrow::before {
  font-size: 14px !important;
}

.text-center {
  text-align: center;
}

.ai-dialog-container {
  display: flex;
  padding: 0px 40px 40px 40px;
  min-height: 538px;
  flex-direction: column;
  justify-content: space-between;
}

.ai-prompt {
  width: 31%;
}

.ai-style {
  width: 64%;
}

.ai-prompt .title {
  font-size: 14px;
  color: #fff;
}

.suggestion-group {
  height: 125px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.suggestion-stickers-group {
  height: 292px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.square-size {
  cursor: pointer;
  width: 90px;
  height: 90px;
  background-color: #252a32;
  border-radius: 8px;
  color: #fff;
  position: relative;
}

.square-size.active {
  border: 2px solid #006dff;
  border-radius: 3px;
  width: 82px;
  height: 82px;
}

.square-size.active span {
  transform: translate(63.5%, -3%);
}

.square-size span {
  position: absolute;
  top: 37%;
  font-size: 12px;
  transform: translatex(74%);
}

.landscape-size.active {
  border: 2px solid #006dff;
  border-radius: 3px;
  width: 82px;
  height: 47.4px;
}

.landscape-size.active span {
  transform: translate(29.5%, -5%);
}

.landscape-size {
  cursor: pointer;
  width: 90px;
  height: 51.4px;
  background-color: #252a32;
  border-radius: 8px;
  color: #fff;
  position: relative;
}

.landscape-size span {
  position: absolute;
  top: 37%;
  font-size: 12px;
  transform: translatex(37%);
}

.portrait-size {
  cursor: pointer;
  position: relative;
  width: 51.4px;
  height: 90px;
  background-color: #252a32;
  border-radius: 8px;
  color: #fff;
}

.portrait-size span {
  font-size: 12px;
  position: absolute;
  top: 37%;
  transform: translateX(19%);
}

.portrait-size.active {
  border: 2px solid #006dff;
  border-radius: 3px;
  height: 82px;
  width: 43.4px;
}

.portrait-size.active span {
  transform: translate(7.5%, -7%);
}

.facebook-cover-size {
  cursor: pointer;
  position: relative;
  width: 108px;
  height: 40px;
  background-color: #252a32;
  border-radius: 8px;
  color: #fff;
}

.facebook-cover-size span {
  font-size: 12px;
  position: absolute;
  top: 28%;
  transform: translateX(19%);
}

.facebook-cover-size.active {
  border: 2px solid #006dff;
  border-radius: 3px;
  width: 108px;
  height: 40px;
}

.facebook-cover-size.active span {
  transform: translate(17.5%, -1%);
}


.ai-style-categories {
  padding-left: 14px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
}

.ai-style-category {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.ai-style-category.active {
  border-bottom: 3px solid #006dff;
}

.style-group {
  gap: 2px;
  margin-top: 16px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.style-group img {
  border-radius: 4px;
}

.style-caption {
  max-width: 90px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 13px;
  padding-top: 2px;
  padding-bottom: 5px;
  text-transform: capitalize;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.ai-prompt .suggestion-item {
  margin-top: 5px;
  border-radius: 20px;
  font-size: 14px;
  padding: 4px 10px;
  color: #fff;
  background-color: #2b3038;
}

.aicreate-btn {
  font-size: 14px;
  margin: auto;
  width: 30%;
  border-radius: 24px;
  padding: 12px 17px;
  background: #006dff;
  color: #fff;
  border: none;
  margin: auto;
  cursor: pointer;
}

.aicreate-btn:hover {
  background: #0060ff;
}

.style-group .selected {
  border: 2px solid #006dff;
  border-radius: 4px;
  padding: 3px;
  width: 86px;
}

.ai-image-container {
  text-align: center;
}

.ai-image-container img {
  border-radius: 8px;
}

.public-page {
  margin-top: 76px;
  background-color: #1b1f25;
}

.go-top {
  z-index: 998;
  position: fixed;
  right: 10px;
  bottom: 25px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.go-top.active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.go-top i {
  background-color: #0046c0;
  color: #ffffff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  font-size: 26px;
  border-radius: 50%;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  margin-right: 28px;
  -webkit-box-shadow: 0px 0px 14px 0px #0046c0;
  box-shadow: 0px 0px 14px 0px #0046c0;
}

.go-top i:hover {
  background-color: #111111;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 14px 0px #111111;
  box-shadow: 0px 0px 14px 0px #111111;
}

.file-picker-galleries {
  overflow-y: auto;
  overflow-x: hidden;
  height: 560px;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.file-picker-gallery-title {
  padding-bottom: 10px;
  padding-top: 10px;
}

.file-picker-gallery-title span {
  color: #fff;
  font-size: 14px;
}

.file-picker-gallery-title a {
  float: right;
  font-size: 12px;
  color: #fff;
}

.background-picker {
  height: 87% !important;
}

.file-picker-back {
  position: absolute;
  left: 20px;
  top: 65px;
  width: 20px;
  padding: 8px;
  background: #006dff;
  border-radius: 20px;
}

.photopicker-dialog-container {
  width: 100%;
  max-width: 1040px;
  height: 100%;
  max-height: 716px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.photopicker-dialog .close-btn {
  position: absolute;
  right: 15px;
  top: 8px;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  background: rgba(57, 55, 55, 0.611764705882353);
  border-radius: 50%;
}

.cutout-dialog-box .close-btn, .filepicker-dialog-box .close-btn, .designpicker-dialog-box .close-btn, .share-dialog-box .close-btn, .saveQualityDialog .close-btn {
  position: absolute;
  right: 15px;
  top: 8px;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  background: rgba(57, 55, 55, 0.611764705882353);
  border-radius: 50%;
}

.close-btn:hover {
  color: #006dff;
}

.font-size-container {
  height: 31px;
  background-color: #1a1e25;
  width: 84px;
  padding: 5px 20px 0px 20px;
  margin-left: 2px;
  margin-right: 2px;
}

.text-font-size {
  color: #fff;
  width: 40px;
  font-size: 16px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .share-tooltiptext {
  display: flex;
  visibility: hidden;
  font-size: 12px !important;
  width: 100%;
  background-color: #000000 !important;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 6px 14px;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: -22px;
  right: 0;
  top: 36px;
  align-items: center;
}

.tooltip .share-tooltiptext.visible {
  visibility: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #006dff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 8px;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: -9px;
  right: 0;
  top: 120%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-transparency {
  width: 66px !important;
  left: 2px;
}

.photopicker-dialog .input-group {
  position: relative;
  width: fit-content;
  margin-top: -36px;
  margin-right: auto;
  margin-bottom: 42px;
  margin-left: auto;
}

.photopicker-dialog .input-group input {
  font-size: 14px;
  width: 400px;
  margin-left: 0px;
}

.quickControls .input-group {
  position: relative;
  width: fit-content;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 42px;
  margin-left: auto;
}

.quickControls .input-group input {
  font-size: 14px;
  width: 400px;
  margin-left: 0px;
}

.start__clear__icon {
  position: absolute;
  cursor: pointer;
  top: 6px;
  right: 6px;
}

.start__search__icon {
  position: absolute;
  top: 6px;
  left: 6px;
}

.startPage #photopicker-album-files {
  background-color: #16191e !important;
  overflow: hidden !important;
  height: auto !important;
}

.startPage #photopicker-album-files ul {
  margin-left: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-right: 25px;
}

.startPage #photopicker-album-files li {
  width: 100%;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 8px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.menu-container .react-autosuggest__suggestions-container--open {
  width: 240px;
  left: 28px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 398px;
  border: 1px solid #5d5d5d;
  background-color: #1b1f25;
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 2;
  overflow-y: auto;
  max-height: 500px;
}

.react-autosuggest__suggestions-list {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0px 0px 0px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #fff;
  color: #000;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 4px 0 0 4px;
  font-size: 16px;
  color: #fff;
  background-color: #252a30;
}

.second-modal {
  background-color: transparent;
}

.unlock-btn {
  position: absolute;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 88px;
  background: #1b1f25;
  font-size: 14px;
  border-radius: 20px;
  padding: 8px 16px;
}

.perm-lock {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.graphicIcon {
  height: 25px;
}

.thumbIcon {
  max-height: 100px;
  max-width: 150px;
  background-color: #aaa;
}

.navigation {
  position: fixed;
  inset: 20px;
  background: #282828;
  width: 80px;
  border-left: 10px solid #1212;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}

.navigation.active {
  width: 300px;
  border-radius: 20px;
}

.toggle {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  background: #c17aff;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle:hover {
  background: #9d2cff;
  transition: all 0.3s ease;
}

.toggle::before,
.toggle::after {
  content: "";
  position: absolute;
  width: 26px;
  height: 3px;
  border-radius: 3px;
  background: #282828;
  transition: 1s;
}

.toggle::before {
  transform: translateY(-5px);
}

.toggle::after {
  transform: translateY(5px);
}

.navigation.active .toggle::before {
  transform: translateY(0px) rotate(-405deg);
}

.navigation.active .toggle::after {
  transform: translateY(0px) rotate(225deg);
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation ul li:hover {
  background: #c17aff;
  color: #222;
}

.navigation ul li:nth-child(1) {
  top: 20px;
  margin-bottom: 40px;
  background: none;
}

.navigation ul li:not(:first-child):hover::before,
.navigation ul li:not(:first-child):hover::after {
  content: "";
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
}

.navigation ul li:not(:first-child):hover::before {
  top: -20px;
  border-bottom-right-radius: 20px;
  box-shadow: 7.5px 7.5px 0 7.5px #c17aff;
}

.navigation ul li:not(:first-child):hover::after {
  bottom: -20px;
  border-top-right-radius: 20px;
  box-shadow: 7.5px -7.5px 0 7.5px #c17aff;
}

.navigation ul li .menu-item {
  position: relative;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #c17aff;
  font-weight: 400;
  cursor: pointer;
  align-items: center;
}

.navigation ul li:hover:not(:first-child) .menu-item {
  color: #f1f1f1;
}

.navigation ul li .menu-item .icon {
  position: relative;
  display: block;
  min-width: 60px;
  line-height: 70px;
  text-align: center;
}

.navigation ul li .menu-item .icon i {
  font-size: 3.75em;
}

.navigation ul li .menu-item .title {
  font-size: 16px;
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* Themes */

.theme-picker {
  position: absolute;
  top: 32px;
  right: 45px;
  background-color: #fefefe;
  padding: 6.5px 25px;
  border-radius: 26px;
  display: flex;
  box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.15);
}

.theme {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #000;
  margin: 0 18px;
  border: 3px solid transparent;
  box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.dark {
  background-color: #222;
}

.light {
  background-color: #fefefe;
}

.purple {
  background-color: #c17aff;
}

.theme.select {
  outline: 1px solid rgba(193, 122, 255, 0.37);
  border: 3px solid #b055ff;
  animation: outline 2.5s linear infinite;
}

.purple.select {
  border-color: #fff;
}

@keyframes outline {
  0% {
    outline-width: 1px;
  }

  50% {
    outline-width: 5px;
  }

  80% {
    outline-width: 2px;
  }

  100% {
    outline-width: 1px;
  }
}

body.darked {
  background-color: #111;
}

body.lighted,
body.lighted .toggle {
  background-color: #9d2cff;
}

body.lighted .navigation {
  background-color: #f1f1f1;
}

body.lighted .navigation ul li .menu-item {
  color: #9d2cff;
}

body.lighted .toggle::after,
body.lighted .toggle::before {
  background: #f1f1f1;
}

body.lighted .navigation ul li:not(:first-child):hover {
  background: #9d2cff;
}

body.lighted .navigation ul li:not(:first-child):hover::before {
  box-shadow: 7.5px 7.5px 0 7.5px #9d2cff;
}

body.lighted .navigation ul li:not(:first-child):hover::after {
  box-shadow: 7.5px -7.5px 0 7.5px #9d2cff;
}

body.purpled {
  background-color: #f1f1f1;
}

body.purpled .navigation {
  background-color: #9d2cff;
}

body.purpled .navigation ul li .menu-item {
  color: #f1f1f1;
}

body.purpled .navigation ul li:not(:first-child):hover {
  background: #f1f1f1;
}

body.purpled .navigation ul li:not(:first-child):hover .menu-item {
  color: #9d2cff;
}

body.purpled .navigation ul li:not(:first-child):hover::before {
  box-shadow: 7.5px 7.5px 0 7.5px #f1f1f1;
}

body.purpled .navigation ul li:not(:first-child):hover::after {
  box-shadow: 7.5px -7.5px 0 7.5px #f1f1f1;
}

body.purpled .toggle {
  background: #f1f1f1;
}

body.purpled .toggle::after,
body.purpled .toggle::before {
  background: #9d2cff;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.grid {
  padding: 10px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.grid>figure {
  border-radius: 8px;
  border: solid 1px #3f3f3f;
  margin: 2px;
  position: relative;
}

.grid>figure>i {
  display: block;
}

.grid>figure>img {
  border-radius: 8px;
  top: 0;
  width: 100%;
  vertical-align: bottom;
  object-fit: cover;
}

.rm-watermark {
  display: flex;
  position: absolute;
  padding: 2px;
  right: 4px;
  bottom: 4px;
  color: #006dff;
  font-size: 13px;
  background: #1b1f25;
  border-radius: 8px;
  align-items: flex-start;
  cursor: pointer;
}

#masonry-layout {
  padding: 12px;
}

.MuiInputBase-input {
  background-color: transparent !important;
  margin-left: 0px !important;
  padding: 4px 0 5px !important;
}

.MuiInputBase-input:focus {
  border-color: #fff !important;
  outline: 0 !important;
  box-shadow: 0px 0px 0px 0px #0e98f4 !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.badge-btn {
  transition: all 0.2s ease-out;
  position: relative;
}

.badge {
  background-color: #ff0000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 800;
  position: absolute;
  top: -12px;
  right: 0px;
  text-align: center;
}

.ai-guide {
  margin-left: 10px;
}

.ai-guide li {
  list-style-type: circle;
}

.x-image-credit {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 200px;
  bottom: 68px;
}

.x-image-credit span {
  color: #b0b1b3;
  font-size: 13px;
}

.my-btn {
  cursor: pointer;
}

.my-btn:hover {
  background-image: linear-gradient(315deg, #8996d8 0%, #0349c8 74%);
}

#link {
  color: #E45635;
  display: block;
  font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  text-decoration: none;
}

#link:hover {
  color: #CCCCCC
}

#link, #link:hover {
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -ms-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

/** BEGIN CSS **/
body {
  background: #1b1f25;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-moz-keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-o-keyframes loading-text-opacity {
  0% {
    opacity: 0
  }

  20% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.loading-container,
.loading-spinner {
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 100%;
}


.loading-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loading-spinner {
  left: -6px;
  top: -6px;
  border: 6px solid transparent;
  border-color: transparent #ffffff transparent #ffffff;
  -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
}

#loading-text {
  -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
  -o-animation: loading-text-opacity 2s linear 0s infinite normal;
  -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: #ffffff;
  font-weight: bold;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  font-size: 14px;
  margin-top: 45px;
  width: 100px;
}

.share-image-header {
  height: 52px;
  margin: auto;
  display: flex;
}

.share-image-container {
  display: flex;
  flex-direction: column;
}

.share-image-container .report-btn {
  display: flex;
  padding: 18px 40px;
}

.share-image-container .report-btn:hover svg path {
  fill: #1976d2;
}

.share-image-container .report-btn span {
  padding-left: 12px;
  font-size: 14px;
  color: #FFF;
  text-wrap: nowrap;
}

.share-image-container .report-btn:hover span {
  color: #1976d2;
}

.share-image-container .share-btn {
  display: flex;
  padding: 18px 40px;
}

.share-image-container .share-btn:hover svg path {
  fill: #1976d2;
}

.share-image-container .share-btn span {
  padding-left: 12px;
  font-size: 14px;
  color: #FFF;
}

.share-image-container .share-btn:hover span {
  color: #1976d2;
}

.share-image-container .download-btn {
  display: flex;
  padding: 18px 40px;
}

.share-image-container .download-btn:hover svg path {
  fill: #1976d2;
}

.share-image-container .download-btn span {
  padding-left: 12px;
  font-size: 14px;
  color: #FFF;
}

.share-image-container .download-btn:hover span {
  color: #1976d2;
}

.share-image-container .share-image-content {
  padding: 0px 120px;
  height: calc(100vh - 104px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.share-image-container .share-image-content img {
  max-height: calc(100vh - 104px);
  border-radius: 8px;
}

@media screen and (max-width: 912px) {
  .share-image-container .share-image-content {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .share-image-container .share-image-content img {
    width: -webkit-fill-available;
  }

  .share-image-container .pizap-logo {
    top: calc(100vh - 104px) !important;
  }
}

@media screen and (max-width: 550px) {
  .share-image-container .download-btn, .share-image-container .report-btn, .share-image-container .share-btn {
    padding: 18px 16px;
  }
  .share-image-container .share-image-content img {
    width: -webkit-fill-available;
  }

  .share-image-content .react-loading-skeleton {
    width: 300px !important;
    height: 300px !important;
  }

  .image-share {
    height: 580px !important;
  }

  .share-dialog-box {
    width: 312px !important;
  }
}

.share-image-container .share-image-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 52px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-image-container .share-image-footer span {
  color: #FFF;
  font-size: 14px;
}

.share-image-container .share-image-footer span:hover {
  color: #1976d2;
}

.share-image-container .pizap-logo {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10;
}

.empty-folder {
  width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.search-masonry {
  padding-right: 38px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.side-navigation {
  position: relative;
  width: 74px;
  display: flex;
}
.side-navigation ul {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.side-navigation ul li {
  position: relative;
  list-style: none;
  width: 74px;
  height: 68px;
  z-index: 1;
}

.side-navigation ul li .icon {
  left: -5px;
  top: 10px;
  position: absolute;
  font-size: 12px;
  text-align: center;
  transition: 0.3s;
  color: #222327;
}

.side-navigation ul li .text {
  position: absolute;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.05em;
  transition: 0.3s;
  transform: translateY(10px);
}

.indicator {
  position: absolute;
  width: 74px;
  height: 68px;
  background: #252a32;
  transition: 0.3s;
}

.side-navigation ul li:nth-child(1).is-active ~ .indicator {
  transform: translateY(calc(68px * 0));
}
.side-navigation ul li:nth-child(2).is-active ~ .indicator {
  transform: translateY(calc(68px * 1));
}
.side-navigation ul li:nth-child(3).is-active ~ .indicator {
  transform: translateY(calc(68px * 2));
}
.side-navigation ul li:nth-child(4).is-active ~ .indicator {
  transform: translateY(calc(68px * 3));
}
.side-navigation ul li:nth-child(5).is-active ~ .indicator {
  transform: translateY(calc(68px * 4));
}
.side-navigation ul li:nth-child(6).is-active ~ .indicator {
  transform: translateY(calc(68px * 5));
}
.side-navigation ul li:nth-child(7).is-active ~ .indicator {
  transform: translateY(calc(68px * 6));
}
.side-navigation ul li:nth-child(8).is-active ~ .indicator {
  transform: translateY(calc(68px * 7));
}
.side-navigation ul li:nth-child(9).is-active ~ .indicator {
  transform: translateY(calc(68px * 8));
}

.side-navigation ul li:hover svg path {
  fill: #006dff;
}

.side-navigation ul li:hover .text {
  color: #006dff;
}

.trial-container {
  display: flex;
  justify-content: space-between;
}

.trial-container .right-side {
  display: block;
  width: 60%;
}

@media screen and (max-width: 1024px) {
  .trial-dialog {
    height: 500px !important;
  }

  .trial-container .left-side {
    padding: 40px 40px;
  }

  .pro-description {
    margin-top: 12px;
  }
}

@media screen and (max-width: 940px) {
  .trial-dialog {
    height: 424px !important;
  }

  .trial-container .left-side {
    width: 40%;
    padding: 40px 40px;
  }

  .trial-container .mt-5 {
    margin-top: 20px !important;
  }

  .pro-description {
    margin-top: 12px !important;
  }

  .option-text, .option-text-trial {
    margin-top: 10px;
  }

  .trial-container .trial-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .trial-dialog {
    height: 424px !important;
  }

  .trial-container .left-side {
    width: 100%;
    padding: 40px 40px;
  }

  .pro-description {
    margin-top: 30px !important;
  }

  .trial-container .right-side {
    display: none;
  }

  .trial-container .trial-btn {
    margin-top: 10px;
  }
}

.menu-photo-skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}